import {Button, ButtonType, Card, Content, Divider, Link, Paragraph, Space, Title} from "./Content";
import "./Defaults.scss";

import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDown, faAngleRight,
    faAngleUp, faChevronDown, faChevronLeft, faChevronRight, faChevronUp,
    faCircleExclamation,
    faInbox,
    faSpinner,
    faTriangleExclamation,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import {capitalizeFirstLetter} from "../../lib/Strings";


// Breadcrumb Component
const Breadcrumb = ({items}: { items: React.ReactNode[] }) => (
  <Space direction="horizontal" GapSm>
        {items.map((item, index) => (
            <Space GapSm key={index} align={"center"}>
                {item}
                {index < items.length - 1 && <span> <span className={"muted-heavy"}><FontAwesomeIcon icon={faChevronRight}/></span> </span>}
            </Space>
        ))}
    </Space>
);

// Pagination Component
type PaginationProps = {
    current: number;
    total: number;
    label?: React.ReactNode;
    onChange: (page: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({ current, total, onChange, label }) => {
    const middlePages = (start: number, end: number) => {
        const pages = [];
        for (let i = start; i < end; i++) {
            pages.push(
                <Button
                    key={i}
                    type={i === current ? 'primary' : 'default'}
                    onClick={() => onChange(i)}
                >
                    {i+1}
                </Button>
            );
        }
        return pages;
    };

    const startPage = Math.max(0, current - 2);
    const endPage = Math.min(total, current + 2);

    return (
        <Space direction={"vertical"}>
            <Paragraph>{label}</Paragraph>
        <Space direction="horizontal" GapSm NoWrap align={"center"}>
            <Button
                disabled={current === 0}
                onClick={() => onChange(current - 1)}
            >
                Prev
            </Button>
            {startPage > 0 && (
                <>
                    <Button onClick={() => onChange(0)}>1</Button>
                    {startPage > 2 && <span>...</span>}
                </>
            )}
            {middlePages(startPage, endPage)}
            {endPage < total && (
                <>
                    {endPage < total - 1 && <span>...</span>}
                    <Button onClick={() => onChange(total-1)}>{total}</Button>
                </>
            )}
            <Button
                disabled={current === total-1}
                onClick={() => onChange(current + 1)}
            >
                Next
            </Button>
        </Space>
        </Space>
    );
};

// Empty Component
const Empty = ({description}: { description: string }) => (
    <Card className="empty">
        <Paragraph><FontAwesomeIcon icon={faInbox}/> {description}</Paragraph>
    </Card>
);

// Empty Component
const Loading = ({description = "Loading", className = ""}: { description?: string, className?: string }) => (
    <Card className={`empty ${className}`}>
        <Paragraph><FontAwesomeIcon spinPulse fixedWidth icon={faSpinner}/> {description}</Paragraph>
    </Card>
);

// Empty Component
const Announcement = ({children, onClose}: { children: React.ReactNode, onClose?: any }) => (
    <div className="announcement pad-sm">
        <Space justify={"space-between"} align={"center"} Gap NoWrap>
            <Paragraph>{children}</Paragraph><FontAwesomeIcon onClick={() => {
            if (onClose) {
                onClose()
            }
        }} fixedWidth className={"icon"} icon={faXmark}/>
        </Space>
    </div>
);

// Announcement Component
export const BlockWarning = ({children, onClose, className}: { children: React.ReactNode, onClose?: any , className?:string}) => (
    <div className={`block-warning pad-sm ${className}`}>
        <Space justify={"space-between"} align={"center"} Gap NoWrap>
            <div>{children}</div>{onClose&&<FontAwesomeIcon onClick={() => {
            if (onClose) {
                onClose()
            }
        }} fixedWidth className={"icon"} icon={faXmark}/>}
        </Space>
    </div>
);


// List Component
const List = ({items}: { items: string[] }) => (
    <Content>
        <ul>
            {items.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    </Content>
);

// Segmented Component
const Segmented = ({options, onChange}: { options: string[]; onChange: (option: string) => void }) => (
    <Space direction="horizontal" Gap>
        {options.map((option, index) => (
            <Button key={index} onClick={() => onChange(option)}>
                {option}
            </Button>
        ))}
    </Space>
);

// Statistic Component
const Statistic = ({title, value, className}: { title: React.ReactNode; value: React.ReactNode , className?: string}) => (
    <Space direction={"vertical"} justify={"space-between"} className={className}>
        <Paragraph className={"muted"}><strong>{title}</strong></Paragraph>
        <Title>{value}</Title>
    </Space>
);

export interface TabItem {
    index: string;
    children: React.ReactNode;
}

interface TabsProps {
    tabs: TabItem[];
    defaultTab: string;
    onTabChange?: (tab: string) => void;
}

const Tabs: React.FC<TabsProps> = ({tabs, defaultTab, onTabChange}) => {
    const [activeTab, setActiveTab] = useState<string>(defaultTab);

    const setTab = (tab: string) => {
        console.log("set tab", tab);
        setActiveTab(tab);
        if (onTabChange) {
            onTabChange(tab)
        }
    }

    return (
        <Space direction="vertical">
            <Space direction="horizontal" GapSm>
                {tabs.map((tab) => (
                    <Button
                        size={"small"}
                        key={tab.index}
                        type={tab.index === activeTab ? 'active' : 'default'}
                        onClick={() => setTab(tab.index)}
                    >
                        {tab.index}
                    </Button>
                ))}
            </Space>
            <Divider/>
            {/* Render the active tab's content */}
            {tabs.find((tab) => tab.index === activeTab)?.children}
        </Space>
    );
};

// Result Component
const Result = ({status, title, subTitle, extra, className}: {
    status: 'success' | 'error' | 'warning',
    title: React.ReactNode,
    subTitle: React.ReactNode,
    extra?: React.ReactNode,
    className?:string
}) => (
    <Card className={[`result result-${status}`, className].join(" ")}>
        <Content Pad Center>
            <Space direction={"vertical"} align={"center"} GapSm>
                <Title>{(status === "error"||status==="warning") ? <FontAwesomeIcon className={status} icon={faTriangleExclamation}/> :
                    <FontAwesomeIcon className={status} icon={faCircleCheck}/>} {title}</Title>
                <Paragraph>{subTitle}</Paragraph>
                {extra}
            </Space>
        </Content>
    </Card>
);

// Modal Component
const Modal = ({visible, title, content, onClose, children, className}: {
    visible: boolean;
    title: React.ReactNode;
    children?: React.ReactNode;
    content?: React.ReactNode;
    onClose: () => void,
    className?:string;
}) => (
    visible ? (
        <div className="modal-container" onClick={onClose}>
            <div className={["modal", className].join(" ")} onClick={e => e.stopPropagation()}>
                <Space direction={"vertical"} GapSm>
                    <Space justify={"space-between"} NoWrap align={"start"} Gap>
                        <Paragraph>{title}</Paragraph>
                        <Button type="default" onClick={() => {
                            if (onClose) {
                                onClose()
                            }
                        }}><FontAwesomeIcon fixedWidth className={"icon"} icon={faXmark}/></Button>
                    </Space>
                    <Space direction={"vertical"} GapSm>
                        <Paragraph>{content||children}</Paragraph>
                    </Space>
                </Space>
            </div>
        </div>
    ) : null
);

// Drawer Component
const Drawer = ({visible, title, content, onClose}: {
    visible: boolean;
    title: React.ReactNode;
    content: React.ReactNode;
    onClose: () => void
}) => (
    visible ? (
        <div className="drawer-container" onClick={onClose}>
            <div className="drawer" onClick={e => e.stopPropagation()}>
                <Space direction={"vertical"} GapSm>
                    <Title>{title}</Title>
                    <Paragraph>{content}</Paragraph>
                    <Button type="primary" onClick={onClose}>Close</Button>
                </Space>
            </div>
        </div>
    ) : null
);


// Skeleton Component
const Skeleton = ({rows, width}: { rows: number, width?: number }) => (
    <div className="skeleton" style={width ? {width: `${width}em`} : {}}>
        {Array.from({length: rows}, (_, index) => (
            <div key={index} className="skeleton-gradient">&nbsp;</div>
        ))}
    </div>
);

interface InlineIconProps {
    children?: React.ReactNode;
}

export const InlineIcon: React.FC<InlineIconProps> = ({children}) => {
    return (
        <span className={`inline-box`}>
            {children}
        </span>
    );
};

interface InlineImageProps {
    src: string;
}

export const InlineImage: React.FC<InlineImageProps> = ({src}) => {
    return (
        <div className={`inline`}>
            <img className={""} src={src}/>
        </div>
    );
};

interface TooltipProps {
    message: React.ReactNode;
    children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({message, children}) => {
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState({x: 0, y: 0, arrowX: "50%"});
    const tooltipRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const showTooltip = () => {
        const containerElement = containerRef.current;
        const tooltipElement = tooltipRef.current;
        console.log(containerElement);

        if (containerElement && tooltipElement) {
            const containerRect = containerElement.getBoundingClientRect();
            const tooltipWidth = tooltipElement.offsetWidth;
            const tooltipHeight = tooltipElement.offsetHeight;
            const screenWidth = window.innerWidth;


            let x = containerRect.left + containerRect.width / 2 - tooltipWidth / 2;
            let y = containerRect.top - tooltipHeight - 10; // 10px above the container

            let arrowX = '50%';


            if (x < 20) {
                arrowX = `${containerRect.left + containerRect.width / 2 - 20}px`;

                x = 20;
            } else if (x + tooltipWidth > screenWidth - 20) {
                arrowX = `${containerRect.left + containerRect.width / 2 - (screenWidth - tooltipWidth - 20)}px`;

                x = screenWidth - tooltipWidth - 20;
            }

            if (y < 0) {
                y = containerRect.bottom + 10; // Move below the container if above would go off-screen
            }

            setPosition({x, y, arrowX});
        }
        setVisible(true);
    };

    const hideTooltip = () => setVisible(false);

    useEffect(() => {
        if (visible) {
            const handleScroll = () => setVisible(false);
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, [visible]);

    return (
        <div ref={containerRef} className="tooltip-container" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
            {children}
            <div ref={tooltipRef} className="tooltip" style={{top: `${position.y}px`, left: `${position.x}px`}}>
                <div className="tooltip-arrow" style={{left: position.arrowX}}></div>
                <div className="tooltip-message">{message}</div>
            </div>
        </div>
    );
};

interface PopconfirmProps {
    message: React.ReactNode;
    children: React.ReactNode;
    onOk: () => void;
    onCancel?: () => void;
}

const Popconfirm: React.FC<PopconfirmProps> = ({ message, children, onOk, onCancel }) => {
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: -999, arrowX: "50%" });
    const popconfirmRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const calculatePosition = () => {
        const containerElement = containerRef.current;
        const popconfirmElement = popconfirmRef.current;

        if (containerElement && popconfirmElement) {
            const containerRect = containerElement.getBoundingClientRect();
            const tooltipWidth = popconfirmElement.offsetWidth;
            const tooltipHeight = popconfirmElement.offsetHeight;
            const screenWidth = window.innerWidth;

            let x = containerRect.left + containerRect.width / 2 - tooltipWidth / 2;
            let y = containerRect.top - tooltipHeight - 10;

            let arrowX = "50%";

            if (x < 20) {
                arrowX = `${containerRect.left + containerRect.width / 2 - 20}px`;
                x = 20;
            } else if (x + tooltipWidth > screenWidth - 20) {
                arrowX = `${containerRect.left + containerRect.width / 2 - (screenWidth - tooltipWidth - 20)}px`;
                x = screenWidth - tooltipWidth - 20;
            }

            if (y < 0) {
                y = containerRect.bottom + 10;
            }

            setPosition({ x, y, arrowX });
        }
    };

    const togglePopconfirm = () => {

        if (visible) {
            setVisible(false);
        } else {
            setVisible(true);
            calculatePosition(); // Calculate position before showing
        }
    };

    const handleOk = () => {
        onOk();
        setVisible(false);
    };

    const handleCancel = () => {
        console.log("CANCEL");
        if (onCancel) onCancel();
        setVisible(false);
    };

    useEffect(() => {
        if (visible) {
            const handleOutsideClick = (event: MouseEvent) => {
                if (
                    popconfirmRef.current &&
                    !popconfirmRef.current.contains(event.target as Node) &&
                    !containerRef.current?.contains(event.target as Node)
                ) {
                    event.preventDefault();
                    console.log("handle click");
                    setVisible(false);
                }
            };

            window.addEventListener("mousedown", handleOutsideClick);
            return () => window.removeEventListener("mousedown", handleOutsideClick);
        }
        calculatePosition();
    }, [visible]);

    return (
        <div ref={containerRef} className="popover-container">
           <div onClick={togglePopconfirm}>{children}</div>

                <div
                    ref={popconfirmRef}
                    className="popover" // Same class as tooltip for consistent styling
                    style={{ top: `${position.y}px`, left: `${position.x}px`, visibility:visible?"visible":"hidden" }}
                >
                   <div className="popover-arrow" style={{ left: position.arrowX }}></div>
                    <Space direction={"vertical"} GapSm>
                    <div className="tooltip-message">{message}</div>
                    <Space className="popconfirm-actions" GapSm>
                        <Button size={"small"} type={"danger"} className="popconfirm-ok" onClick={handleOk}>
                            OK
                        </Button>
                        <Button size={"small"} className="popconfirm-cancel" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Space>
                    </Space>
                </div>

        </div>
    );
};

export default Popconfirm;





interface CircleElementProps {
    imageSrc: string;
    altText: string;
    onClick?: () => void;
}

const ScrollableElement: React.FC<CircleElementProps> = ({imageSrc, altText, onClick}) => {
    return (
        <div className={"scrollable-element"} onClick={onClick}>
            <div className="circle-element">
                <img src={imageSrc} alt={altText}/>
            </div>
            <p>{altText}</p>
        </div>
    );
};

interface ScrollableContainerProps {
    children: React.ReactNode;
}

const ScrollableContainer: React.FC<ScrollableContainerProps> = ({children}) => {
    return (

        <div className="scrollable-container">
            {children}
        </div>
    );
};


interface Option<T> {
    element: React.ReactNode;
    value: T;
    category?: string; // Optional category for sorting
}

interface SelectProps<T> {
    options: Option<T>[];
    defaultValue?: T;
    placeholder?: React.ReactNode | string;
    onSelected: (value: T) => void;
    type?: ButtonType;
    categorize?: {
        property: string;
        order?: string[];
    };
}

const Select: React.FC<SelectProps<any>> = ({
                                                options,
                                                defaultValue,
                                                placeholder,
                                                onSelected,
                                                type,
                                                categorize
                                            }) => {
    const [isActive, setIsActive] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const dropdownNode = useRef<HTMLDivElement>(null);

    const toggleMenu = () => {
        setIsActive(current => !current);
    };

    const selectOption = (value: any) => {
        setSelectedValue(value);
        onSelected(value);
        setIsActive(false);
    };

    const closeMenu = (event: MouseEvent) => {
        if (dropdownNode.current && !dropdownNode.current.contains(event.target as Node)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", closeMenu);
        return () => {
            document.removeEventListener("mousedown", closeMenu);
        };
    }, []);

    useEffect(() => {
        setSelectedValue(defaultValue || undefined);
    }, [defaultValue]);

    // Function to categorize and sort options
    const getCategorizedOptions = () => {
        const categorizedOptions: { [key: string]: Option<any>[] } = {};
        const uncategorizedOptions: Option<any>[] = [];

        options.forEach(option => {
            const category = option.category || 'Uncategorized';
            if (categorize && category !== 'Uncategorized') {
                if (!categorizedOptions[category]) {
                    categorizedOptions[category] = [];
                }
                categorizedOptions[category].push(option);
            } else {
                uncategorizedOptions.push(option);
            }
        });

        // Sort categories based on the provided order
        const orderedCategories = categorize?.order || [];
        const sortedCategories = Object.keys(categorizedOptions).sort((a, b) => {
            const indexA = orderedCategories.indexOf(a);
            const indexB = orderedCategories.indexOf(b);
            return (indexA !== -1 ? indexA : Infinity) - (indexB !== -1 ? indexB : Infinity);
        });

        return { categorizedOptions, sortedCategories, uncategorizedOptions };
    };

    const { categorizedOptions, sortedCategories, uncategorizedOptions } = getCategorizedOptions();

    const shouldShowCategories = categorize && sortedCategories.length > 0;

    return (
        <div ref={dropdownNode} className="dropdown-container">
            <Button onClick={toggleMenu} className="menu-button" type={type || "ghost"}>
                <Space align={"center"} Gap NoWrap>
                    {(typeof selectedValue === "string" && selectedValue !== "")
                        ? (options.find(option => option.value === selectedValue)?.element)
                        : (placeholder || <span>Select an option</span>)}
                    <FontAwesomeIcon fixedWidth icon={isActive ? faAngleDown : faAngleRight} />
                </Space>
            </Button>

            <div className={`dropdown-inner left ${isActive ? "active" : ""}`}>
                <Space direction={"vertical"} GapSm NoWrap>
                    {shouldShowCategories ? (
                        <>
                            {sortedCategories.map(category => (
                                <div key={category}>
                                    <Space direction={"vertical"} GapSm><Space GapSm className={"no-select"}><strong className={"muted-heavy"}>{capitalizeFirstLetter(category)}</strong> <Divider/></Space>
                                    {categorizedOptions[category].map(option => (
                                        <Link href={"#"} key={String(option.value)} className="dropdown-item"
                                              onClick={() => selectOption(option.value)}>
                                            {option.element}
                                        </Link>
                                    ))}</Space>
                                </div>
                            ))}
                        </>
                    ) : (
                        uncategorizedOptions.map(option => (
                            <Link href={"#"} key={String(option.value)} className="dropdown-item"
                                  onClick={() => selectOption(option.value)}>
                                {option.element}
                            </Link>
                        ))
                    )}
                </Space>
            </div>
        </div>
    );
};


interface BlockquoteProps {
    children?: React.ReactNode;
}

const Blockquote: React.FC<BlockquoteProps> = ({children}) => {
    return (
        <blockquote className="stylish-blockquote">
            <Space direction={"horizontal"} NoWrap><span className="quote-mark">“</span>
                {children}
                <span className="quote-mark">”</span></Space>
        </blockquote>
    );
};


interface CollapsibleProps {
    title: React.ReactNode;
    children: React.ReactNode;
}


const Collapsible: React.FC<CollapsibleProps> = ({title, children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [maxHeight, setMaxHeight] = useState<string>('0px');

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Update maxHeight when `isOpen` changes or content changes
        if (contentRef.current) {
            //const contentHeight = contentRef.current.scrollHeight;
            setMaxHeight(isOpen ? `${480}px` : '0px');
        }
    }, [isOpen, children]);

    return (
        <div className={`collapsible-list ${isOpen ? 'open' : ''}`}>
            <div className="collapsible-header" onClick={toggleCollapse}>
                <div className="title-space">
                    <span>{title}</span>
                    <FontAwesomeIcon rotation={isOpen ? 90 : undefined} icon={faChevronRight}/>
                </div>
            </div>
            <div
                ref={contentRef}
                className="collapsible-content"
                style={{
                    maxHeight: maxHeight,
                    // overflow: 'scroll'
                }}
            >
                {<div className="content-wrapper">
                    {children}
                </div>}
            </div>
        </div>
    );
};


export const Collapsible2: React.FC<CollapsibleProps> = ({title, children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // Update maxHeight when `isOpen` changes or content changes
    }, [isOpen, children]);

    return (
        <div className={`collapsible2-list ${isOpen ? 'open' : ''}`}>
            <div className="collapsible-header" onClick={toggleCollapse}>
                <div className="title-space">
                    <span>{title}</span>
                    <FontAwesomeIcon rotation={isOpen ? 90 : undefined} icon={faChevronRight}/>
                </div>
            </div>
            {isOpen&&<div
                ref={contentRef}
                style={{
                     overflow: 'visible'
                }}
            >
                {<div className="content-wrapper">
                    {children}
                </div>}
            </div>}
        </div>
    );
};

interface Props {
    children?: React.ReactNode;
    backgroundImage?: string;
    style?:any;
}

const Cutout: React.FC<Props> = ({children,style, backgroundImage}) => {
    return (
        <div className="cutout" style={{backgroundImage: `url(${backgroundImage})`,...style}}>
            {children}
        </div>
    );
};


export {
    Breadcrumb,
    Pagination,
    Empty,
    Loading,
    List,
    Segmented,
    Statistic,
    Tabs,
    Result,
    Modal,
    Drawer,
    Skeleton,
    ScrollableContainer,
    ScrollableElement,
    Announcement,
    Select,
    Blockquote,
    Collapsible,
    Cutout,
    Popconfirm
};