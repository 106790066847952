import React, { useState, useEffect } from "react";
import { Button, Content, Input, Space, Title, Row, Col } from "../layout/Content";

interface QueryBuilderProps {
    tables: string[];
    columns: { [table: string]: string[] }; // Map of table names to their columns
    onUpdate: (sql: string) => void;
}

interface Condition {
    table: string;
    column: string;
    operator: "eq" | "lt" | "gt" | "lte" | "gte" | "ne" | "LIKE";
    value: string;
    logicalOperator?: "AND" | "OR";
}

const QueryBuilder: React.FC<QueryBuilderProps> = ({ tables, columns, onUpdate }) => {
    const [selectedTables, setSelectedTables] = useState<string[]>([]);
    const [selectedColumns, setSelectedColumns] = useState<string[]>(["*"]); // Default to `*`
    const [conditions, setConditions] = useState<Condition[]>([]);

    const generateSQL = () => {
        const selectClause =
            selectedColumns.length && selectedColumns[0] !== "*"
                ? selectedColumns.join(", ")
                : "*"; // Default to `*` if no specific columns are selected
        const fromClause = selectedTables.join(", ");
        const whereClause = conditions
            .map((cond, i) => {
                const operatorMap: Record<string, string> = {
                    eq: "=",
                    lt: "<",
                    gt: ">",
                    lte: "<=",
                    gte: ">=",
                    ne: "!=",
                    LIKE: "LIKE",
                };
                const operator = operatorMap[cond.operator] || cond.operator;

                const coercedValue =
                    operator !== "LIKE" && !isNaN(Number(cond.value)) ? cond.value : `'${cond.value}'`;

                return `${i > 0 && cond.logicalOperator ? cond.logicalOperator + " " : ""}${cond.table}.${
                    cond.column
                } ${operator} ${coercedValue}`;
            })
            .join(" ");
        return `SELECT ${selectClause} FROM ${fromClause}${whereClause ? ` WHERE ${whereClause}` : ""}`;
    };

    useEffect(() => {
        onUpdate(generateSQL());
    }, [selectedTables, selectedColumns, conditions, onUpdate]);

    const addCondition = () => {
        const initialTable = selectedTables[0] || "";
        setConditions([
            ...conditions,
            {
                table: initialTable,
                column: columns[initialTable]?.[0] || "",
                operator: "eq",
                value: "",
                logicalOperator: conditions.length > 0 ? "AND" : undefined,
            },
        ]);
    };

    const removeCondition = (index: number) => {
        const newConditions = [...conditions];
        newConditions.splice(index, 1);
        setConditions(newConditions);
    };

    const updateCondition = (index: number, key: keyof Condition, value: any) => {
        const newConditions = [...conditions];
        // @ts-ignore
        newConditions[index][key] = value;
        setConditions(newConditions);
    };

    return (
        <Content>
            <Space direction="vertical" Gap Full>
                <Row GapSm>
                    <Col xs={12}>
                        <Space direction="vertical" GapSm>
                            <strong>Select Tables</strong>
                            <select
                                multiple
                                value={selectedTables}
                                onChange={(e) => {
                                    const selected = Array.from(e.target.selectedOptions, (option) => option.value);
                                    setSelectedTables(selected);
                                    setSelectedColumns(["*"]); // Reset to default `*` when tables change
                                }}
                            >
                                {tables.map((table) => (
                                    <option key={table} value={table}>
                                        {table}
                                    </option>
                                ))}
                            </select>
                        </Space>
                    </Col>
                    <Col xs={12}>
                        <Space direction="vertical" GapSm>
                            <strong>Select Columns</strong>
                            <select
                                multiple
                                value={selectedColumns}
                                onChange={(e) => {
                                    const selected = Array.from(e.target.selectedOptions, (option) => option.value);
                                    setSelectedColumns(selected.includes("*") ? ["*"] : selected);
                                }}
                            >
                                {["*"].concat(
                                    selectedTables.flatMap((table) =>
                                        columns[table]?.map((col) => `${table}.${col}`)
                                    )
                                ).map((col) => (
                                    <option key={col} value={col}>
                                        {col}
                                    </option>
                                ))}
                            </select>
                        </Space>
                    </Col>
                </Row>

                <Space direction="vertical" GapSm>
                    {conditions.map((condition, index) => (
                        <Row key={index} Gap align="center">
                            {index > 0 && (
                                <select
                                    value={condition.logicalOperator || ""}
                                    onChange={(e) => updateCondition(index, "logicalOperator", e.target.value)}
                                >
                                    <option value="AND">AND</option>
                                    <option value="OR">OR</option>
                                </select>
                            )}
                            <select
                                value={condition.table}
                                onChange={(e) => updateCondition(index, "table", e.target.value)}
                            >
                                {tables.map((table) => (
                                    <option key={table} value={table}>
                                        {table}
                                    </option>
                                ))}
                            </select>
                            <select
                                value={condition.column}
                                onChange={(e) => updateCondition(index, "column", e.target.value)}
                            >
                                {columns[condition.table]?.map((col) => (
                                    <option key={col} value={col}>
                                        {col}
                                    </option>
                                ))}
                            </select>
                            <select
                                value={condition.operator}
                                onChange={(e) => updateCondition(index, "operator", e.target.value)}
                            >
                                <option value="eq">=</option>
                                <option value="lt">&lt;</option>
                                <option value="gt">&gt;</option>
                                <option value="lte">&lt;=</option>
                                <option value="gte">&gt;=</option>
                                <option value="ne">!=</option>
                                <option value="LIKE">LIKE</option>
                            </select>
                            <Input
                                type="text"
                                value={condition.value}
                                placeholder="Value"
                                onChange={(e) => updateCondition(index, "value", e.target.value)}
                            />
                            <Button type="danger" onClick={() => removeCondition(index)}>
                                Remove
                            </Button>
                        </Row>
                    ))}
                    <Button type="primary" onClick={addCondition}>
                        Add Condition
                    </Button>
                </Space>
            </Space>
        </Content>
    );
};

export default QueryBuilder;
