import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faParagraph,
    faHashtag,
    faList,
    faCircle,
    faObjectGroup
} from "@fortawesome/free-solid-svg-icons";
import {Paragraph, Space} from "../layout/Content";

type JSONValue = string | number | boolean | null | JSONValue[] | { [key: string]: JSONValue };

interface TreeProps {
    data: { [key: string]: JSONValue };
    indent?: number;
}

const Tree: React.FC<TreeProps> = ({ data, indent = 0 }) => {
    const renderValue = (key: string, value: JSONValue, currentIndent: number): JSX.Element => {
        if (typeof value === "string") {
            return (
                <div style={{ marginLeft: `20px`, marginBottom:"0.5em" }} key={key}>
                    <FontAwesomeIcon icon={faParagraph} fixedWidth className={"muted-heavy"}/> <strong className={"muted"}>{key}</strong>: <em>"{value}"</em>
                </div>
            );
        } else if (typeof value === "number") {
            return (
                <div style={{ marginLeft: `20px`, marginBottom:"0.5em" }} key={key}>
                    <FontAwesomeIcon icon={faHashtag} fixedWidth className={"muted-heavy"}/> <strong className={"muted"}>{key}</strong>: {value}
                </div>
            );
        } else if (Array.isArray(value)) {
            return (
                <div style={{ marginLeft: `20px`, marginBottom:"0.5em" }} key={key}>
                    <FontAwesomeIcon icon={faList}  fixedWidth className={"muted-heavy"}/> <strong className={"muted"}>{key}</strong> <strong>[</strong>
                    {(value&&value.length!==0)&&<Space direction={"vertical"} style={{marginTop:"0.5em"}}>
                        {value.map((item, index) => {
                            return renderValue(`[${index}]`, item, currentIndent + 1);
                        })}
                    </Space>}
                    <strong>]</strong>
                </div>
            );
        } else if (typeof value === "object" && value !== null) {
            return (
                <div style={{ marginLeft: `20px`, marginBottom:"0.5em" }} key={key}>
                    <FontAwesomeIcon icon={faObjectGroup}  fixedWidth className={"muted-heavy"}/> <strong className={"muted"}>{key}</strong> <strong>{"{"}</strong>
                    {Object.entries(value).length>0&&<Space direction={"vertical"} style={{marginTop:"0.5em"}}>
                        {Object.entries(value).map(([nestedKey, nestedValue]) => {
                        return renderValue(nestedKey, nestedValue, currentIndent + 1)
                    })}</Space>}
                    <strong>{"}"}</strong>
                </div>
            );
        } else if (typeof value === "boolean") {
            return (
                <div style={{ marginLeft: `20px`, marginBottom:"0.5em" }} key={key}>
                    <FontAwesomeIcon icon={faCircle} fixedWidth className={"muted-heavy"}/> <strong className={"muted"}>{key}</strong>: {value.toString()}
                </div>
            );
        } else if (value === null) {
            return (
                <div style={{ marginLeft: `20px`, marginBottom:"0.5em" }} key={key}>
                    <FontAwesomeIcon icon={faCircle} fixedWidth className={"muted-heavy"}/> <strong className={"muted"}>{key}</strong>: null
                </div>
            );
        }
        return <div />;
    };

    return (
        <Space direction={"vertical"}>
            {Object.entries(data).map(([key, value]) => (
                <Paragraph key={key}>{renderValue(key, value, indent)}</Paragraph>
            ))}
        </Space>
    );
};

export default Tree;
