interface ParsedSchema {
    database: string;
    tables: {
        [tableName: string]: {
            columns: { name: string; type: string }[];
        };
    };
}

export const parseSQLSchema = (schema: string[]): ParsedSchema => {
    const result: ParsedSchema = { database: "", tables: {} };

    schema.forEach((entry) => {
        // Parse database name
        if (entry.startsWith("# Database:")) {
            const dbMatch = entry.match(/# Database: (\w+)/);
            if (dbMatch) {
                result.database = dbMatch[1];
            }
        }

        // Parse table definitions
        if (entry.startsWith("CREATE TABLE")) {
            const tableMatch = entry.match(/CREATE TABLE `(\w+)`/);
            if (tableMatch) {
                const tableName = tableMatch[1];
                const columnMatches = Array.from(
                    entry.matchAll(/`(\w+)` ([^,\n]+)/g)
                ).map(([, name, type]) => ({ name, type }));

                result.tables[tableName] = { columns: columnMatches };
            }
        }
    });

    return result;
};
