import React, {useEffect, useState} from "react";
import {
    Button,
    ButtonSize,
    Card,
    Col,
    Content, Input,
    Layout,
    Page,
    Paragraph,
    Pill,
    Row,
    Space,
    Title
} from "../layout/Content";
import {useNavigate, useParams} from "react-router-dom";
import {
    faArrowUpFromBracket, faChartBar,
    faDiagramNext,
    faPlus,
    faSpinner,
    faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Badge from "../part/Badge";
import {Blockquote, Collapsible, Empty, Loading, Modal, Result, Tabs} from "../layout/Defaults";
import {faCircleCheck, faCopy, faEdit} from "@fortawesome/free-regular-svg-icons";
import {Footer, Header} from "./Navigation";
import Inference, {renderResponse} from "./Inference";
import Datasource from "./Datasource";
import PaginatedCollectionList from "./PaginatedCollectionList";
import {github, xcode} from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";
import {timeAgo} from "../../lib/Time";
import FineTuneConnectorForm, {FineTuneModel} from "./FineTuneConnectorForm";
import Tree from "./Tree";
import {Menu} from "../Generated";


const renderPostItem = (post: any, size?: ButtonSize) => {

    if (size === "small"){
        return <Space Grow><Card PadSm Wide>
            {post.type||post.task||""} {timeAgo(post.createdAt)} {post.labels?.rating||"-"}
        </Card></Space>
    }

    const vis = (type: string) => {
       return  <Col xs={24}><Card PadSm>
           <Row GapSm>
            <Col xs={12} md={6}>
                <strong>Alignment Intent </strong>
                <Paragraph>{post.task}</Paragraph>
            </Col>

            {post.data.system&&<Col xs={24}>
                <Collapsible title="System Prompt">
                    <Space NoWrap direction={"vertical"} PadSm>
                        <Tree data={{system:(post.data.system)}}/>
                    </Space>
                </Collapsible>
            </Col>}

           {post.data.schema&&<Col xs={24}>
               <Collapsible title="Data Source Schema">
                   <Space NoWrap direction={"vertical"} PadSm>
                       <Tree data={{schema:(post.data.schema)}}/>
                   </Space>
               </Collapsible>
           </Col>}

           {post.data.prompt&&<Col xs={24}>
               <Space direction={"vertical"} GapSm>
                   <strong>User Prompt</strong>
                   <Blockquote>{post.data.prompt}</Blockquote>
               </Space>
           </Col>}


           {post.data.alignment&&<Col xs={24}>
                <Collapsible title="Alignment Response">
                    <Space NoWrap direction={"vertical"} PadSm>
                        <Tree data={(post.data.alignment)}/>
                    </Space>
                </Collapsible>
            </Col>}

               {post.data.data&&<Col xs={24}>
                   <Collapsible title="Alignment Data">
                       <Space NoWrap direction={"vertical"} PadSm>
                           <Tree data={(post.data.data)}/>
                       </Space>
                   </Collapsible>
               </Col>}

               {post.data.inference&&<Col xs={24}>
                <Collapsible title="Inference Response">
                    <Space NoWrap direction={"vertical"} PadSm>
                        <Tree data={(post.data.inference)}/>
                    </Space>
                </Collapsible>
            </Col>}




            <Col xs={24} md={6}>
                <strong>Alignment Data Source</strong>
                <Paragraph>{post.data.prompt?.datasource_uuid || "unknown data source"}</Paragraph>
            </Col>
            <Col xs={12} md={6}>
                <strong>Mapping Score</strong>
                <Paragraph>not started</Paragraph>
            </Col>
            <Col xs={12} md={6}>
                <strong>Associated Results</strong>
                <Paragraph>{post.data.associated?.results_uuid||"N/A"}</Paragraph>
            </Col>

           </Row></Card></Col>
    }
    return (
                vis(post.type)
    );
}


const Logs: React.FC = () => {

    const {page} = useParams<{ page: string }>();

    const navigate = useNavigate();
    const {controller} = useParams<{ controller: string }>();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [selectedLog, setSelectedLog] = useState<any>(null);


    const btn = () => {
        return <Button type={"primary"} onClick={() => {
            navigate("/logs/add");
        }}><FontAwesomeIcon icon={faPlus} fixedWidth/>Add New Log</Button>;
    };

    const logToExample = (log: any):FineTuneModel => {
        return {
            task: log?.task||"unknown",
            labels: {human_labeled: false, rating: 0},
            options: {public: false},
            data: {
                data: log?.data?.data || [],
                prompt: log?.data?.prompt || "",
                response: log?.data.response,
                // results: log?.data?.results,
                schema: log?.data?.schema || []
            }
        }
    };


    if (controller === "add") {
        return <div>Add Log Form Component Here</div>;
    }




    return (
        <Layout>
            <Header subtitle={"Logging"}/>
            <Page Grow className="app-main">
                <Content className={"limits wide pad-content full"}>
                    <Row Gap className={"full"}>
                        <Col xs={24} md={8} lg={6} xl={4} className={"hide-sm"}>
                            <Menu/>
                        </Col>

                        <Col xs={24} md={16} lg={18} xl={20}>
                            <Space justify="space-between" direction="vertical" GapSm Full>
                                <Space direction="vertical" GapSm>



                                        <Space Wide Gap justify={"space-between"} align={"center"}>
                                            <Title>Logs</Title>{btn()}
                                        </Space>


                                        <PaginatedCollectionList
                                            selectedCollection="log"
                                            renderItem={renderPostItem}
                                            actions={[{
                                                onClick:(e:any)=>{
                                                    console.log('NEW EXAMPLE FROM ', e);
                                                    setSelectedLog(e);
                                                    setIsModalVisible(true);

                                                },
                                                element:
                                                    <FontAwesomeIcon icon={faArrowUpFromBracket}
                                                                     fixedWidth/>
                                            }]}
                                        />




                                </Space>
                            </Space>
                        </Col>

                    </Row>
                    <Modal
                        visible={isModalVisible}
                        title={`Create Fine-tuning example from Log`}
                        content={
                            <FineTuneConnectorForm finetune={logToExample(selectedLog)}/>
                        }
                        onClose={()=>{
                            setIsModalVisible(false);
                        }}
                    />
                </Content>
            </Page>
            <Footer/>
        </Layout>
    );

};

export default Logs;
