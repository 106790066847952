import React, {useEffect, useState} from "react";
import DatasourceConnectorForm, {getNickname} from "./DatasourceConnectorForm";
import {Button, Card, Col, Paragraph, Row, Space, Title} from "../layout/Content";
import {useNavigate, useParams} from "react-router-dom";
import {faPlus, faSpinner, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Badge from "../part/Badge";
import {Breadcrumb, Collapsible, Empty, Loading, Modal, Result} from "../layout/Defaults";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";

const DataBoxDocs: React.FC = () => {

    const navigate = useNavigate();
    const {page} = useParams<{ page: string }>();
    const {controller} = useParams<{ controller: string }>();


    const [sources, setSources] = useState<any[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


    const btn = ()=>{
        return <Button type={"default"} onClick={()=>{
            navigate("/databox/sources/add");
        }}><FontAwesomeIcon icon={faPlus} fixedWidth />View ClusterFX Docs</Button>;
    }


    return (<Space className="limits pad-content" direction="vertical" Gap>

        <Space Wide Gap justify="space-between" align="center">
            <Title Large>DataBox Toolkit Documentation</Title>
            {btn()}
        </Space>

        <Row Gap>
            <Col xs={24}>
                <Card Pad>
                    <Space direction="vertical" Gap>
                        <Breadcrumb items={['DataBox Toolkit', 'Getting Started']} />
                        <Title Large>Getting Started</Title>
                        <Paragraph>
                            Welcome to DataBox Toolkit! Here, you can run Inference on public and private models, analyze data, and fine-tune machine learning models. Follow this guide to get started.
                        </Paragraph>

                        <Paragraph>
                            <strong>Step 1:</strong> Sign up and explore the <strong>Inference</strong> section to perform your first task using our pre-trained models.
                        </Paragraph>
                        <Paragraph>
                            <strong>Step 2:</strong> Connect your data sources and explore them using our <strong>Data Explorer</strong> tool. DataBox Toolkit makes it easy to integrate your data and build personalized models.
                        </Paragraph>

                    </Space>
                </Card>
            </Col>

            <Col xs={24}>
                <Card Pad>
                    <Space direction="vertical" Gap>
                        <Breadcrumb items={['DataBox Toolkit', 'Adding a Data Source']} />
                        <Space align={"center"} justify={"space-between"}><Title Large>Adding a Data Source</Title><span className={"warning"}><FontAwesomeIcon size={"2x"} icon={faTriangleExclamation} fixedWidth/></span></Space>
                        <Paragraph>
                            Connecting a data source is crucial for analyzing your database and running powerful inferences.
                        </Paragraph>
                        <Collapsible title={"Connecting your Data Source"}>
                            <Space GapSm direction={"vertical"} Pad>
                            <Paragraph>
                            <strong>Step 1:</strong> Navigate to the <strong>Data Sources</strong> section and click <strong>Add New Source</strong>.
                        </Paragraph>
                        <Paragraph>
                            <strong>Step 2:</strong> Enter the connection credentials for your database (currently MariaDB, with NoSQL support coming soon). Once connected, Data Explorer will automatically retrieve and visualize your data.
                        </Paragraph>
                            </Space>
                        </Collapsible>
                    </Space>
                </Card>
            </Col>

            <Col xs={24}>
                <Card Pad>
                    <Space direction="vertical" Gap>
                        <Breadcrumb items={['DataBox Toolkit', 'Running Inference']} />
                        <Title Large>Run Inference using Your Data Source</Title>
                        <Paragraph>
                            Once your data source is connected, you can use it for advanced inference tasks, build dashboards, and create custom fine-tuned models.
                        </Paragraph>
                        <Paragraph>
                            <strong>Step 1:</strong> Go to the <strong>Inference</strong> section and select a pre-trained model. Customize its parameters to suit your dataset, and start running your inferences.
                        </Paragraph>
                    </Space>
                </Card>
            </Col>

            <Col xs={24}>
                <Card Pad>
                    <Space direction="vertical" Gap>
                        <Breadcrumb items={['DataBox Toolkit', 'Fine-Tuning']} />
                        <Title Large>Fine-Tuning</Title>
                        <Paragraph>
                            Build fine-tuned models by feeding specialized data to our base models. Fine-tuning helps create models optimized for specific tasks.
                        </Paragraph>
                        <Paragraph>
                            <strong>Step 1:</strong> Select the data for fine-tuning, configure your parameters, and start the process.
                        </Paragraph>
                        <Paragraph>
                            Use Case: Train a fine-tuned model to detect specific patterns in sales data and provide predictions for future sales trends.
                        </Paragraph>
                    </Space>
                </Card>
            </Col>

            <Col xs={24}>
                <Card Pad>
                    <Space direction="vertical" Gap>
                        <Breadcrumb items={['DataBox Toolkit', 'Tailored Models']} />
                        <Title Large>Tailored Models</Title>
                        <Paragraph>
                            Once fine-tuning is complete, your model is optimized and ready for specific use cases, such as detecting anomalies, classifying data, or making predictions.
                        </Paragraph>
                        <Paragraph>
                            <strong>Step 1:</strong> Use the <strong>Models</strong> section to deploy your tailored models and integrate them into your workflows.
                        </Paragraph>
                    </Space>
                </Card>
            </Col>

            <Col xs={24}>
                <Card Pad>
                    <Space direction="vertical" Gap>
                        <Breadcrumb items={['DataBox Toolkit', 'Dashboards and API Endpoints']} />
                        <Title Large>Dashboards, API Endpoints, and Whitelabels</Title>
                        <Paragraph>
                            DataBox Toolkit offers powerful API endpoints, dashboards, and whitelabel solutions. Once your model is ready, generate an API Key, and use it to call inferences from your application.
                        </Paragraph>
                        <Paragraph>
                            <strong>Step 1:</strong> Head to the <strong>API Keys</strong> section and generate a key. You can now access your inference models programmatically via REST APIs.
                        </Paragraph>
                        <Paragraph>
                            Use Case: Automate tasks such as content moderation in a social media platform by integrating your model with the provided API endpoint.
                        </Paragraph>
                    </Space>
                </Card>
            </Col>

        </Row>


    </Space>);
};

export default DataBoxDocs;