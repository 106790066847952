import {Button, Card, Col, Input, Link, Row, Small, Space, Title} from "../layout/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import React, {useState} from "react";
import {Trademark} from "./Trademark";
import useFormMethodValidation from "../../lib/FormMethodValidation";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "../layout/Defaults";

export function SignIn({defaultMode = false}:{defaultMode?:boolean}) {

    const [mode, setMode] = useState<boolean>(defaultMode===undefined?false:defaultMode);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const { setError, getError, getHandlers } = useFormMethodValidation(['email', 'password']);


    const getMessage = (m?: boolean) => {
        return ((m !== undefined) ? m : mode) ? "Sign up" : "Log in";
    }

    const getCheckboxMessage = (m?: boolean) => {
        return ((m !== undefined) ? m : mode) ? <p>By signing up you agree to our <Link span href={"/info/terms-of-service"}>Terms of Service</Link> and <Link span href={"/info/privacy-policy"}>Privacy Policy</Link>, and confirm that you are at least 18 years old.</p> : "";
    }

    const handleFormSubmission = async () => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/auth/${mode ? "signup" : "login"}`, {
                method: 'POST',
                credentials: 'include',
            //    mode: 'cors', // This needs to be 'cors' to follow CORS rules
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });
            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.errors) {
                    errorData.errors.forEach((error: { path: string; msg: string }) => {
                        setError(error.path, error.msg);
                    });
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data: any = await response.json();
            console.log('USER DATA', data);
        } catch (err) {
            console.error(err);
        }
    };

    const switchModes = ()=>{
        getHandlers().email.onChange();
        getHandlers().password.onChange();
        setMode(!mode);
    }

    return (
        <Row Gap style={{maxWidth:"620pt"}}>
            <Col xs={24} md={8}>
                <div className={"Pad HeroMini"} style={{backgroundImage:"url(/hero03.jpeg)", height:"100%"}}>
                <Space direction={"vertical"}>
                    <Title Muted>the</Title>
                    <Title Large>Peek<Trademark/></Title>
                </Space>
                </div>
            </Col>
            <Col xs={24} md={16}>
                <div>
                    <Space direction={"vertical"} GapSm>
                        <Title>{getMessage()}</Title>
                        <p>Join now! It's free and only takes a minute!</p>
                        <Space direction={"vertical"} GapSm>
                            <Space direction={"vertical"}>
                                <label>Username</label><Input
                                type={"email"}
                                placeholder={"Email"}
                                notification={getError('email')&&<Tooltip message={getError('email')}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation}/></Tooltip> }
                                onChange={(e) => {
                                    getHandlers().email.onChange(e);
                                    setEmail(e.target.value);
                                }}
                                className={getError('email') ? "Error" : ""}
                            />
                            {getError('email') && <Small className={"Text Dark Error"}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation}/> {getError('email')}</Small>}
                            </Space>
                            <Space direction={"vertical"}>
                            <Input
                                type={"password"}
                                placeholder={"Password"}
                                notification={getError('password')&&<Tooltip message={getError('password')}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation}/></Tooltip> }
                                onChange={(e) => {
                                    getHandlers().password.onChange(e);
                                    setPassword(e.target.value);
                                }}
                                className={getError('password') ? "Error" : ""}
                            />
                                {getError('password') && <Small className={"Text Dark Error"}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation}/> {getError('password')}</Small>}
                            </Space>

                            <Button onClick={handleFormSubmission}>{getMessage()}</Button>
                            <Small className={"pad-content"} style={{maxWidth:"45em"}}>{getCheckboxMessage()}</Small>

                            {mode&&<Space GapSm justify={"center"}><p>Already have an account?</p> &bull; <Link href={"#"} onClick={() => {
                                switchModes();
                            }}>{getMessage(!mode)}</Link></Space>}
                            {!mode&&<Space GapSm justify={"center"}><Link href={"#"}>Forgot Password?</Link> &bull; <Link href={"#"} onClick={() => {
                                switchModes();
                            }}>{getMessage(!mode)}</Link></Space>}

                            <Space direction={"vertical"} Gap>
                                <Button href={"https://api.clusterfx.org/auth/google"} type={"default"}
                                        icon={<FontAwesomeIcon icon={faGoogle}/>}>{getMessage()} with Google</Button>
                            </Space>

                        </Space>


                    </Space>
                </div>
            </Col>
        </Row>
    )
}