import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Button, Card, Input, Link, Pill
} from "./layout/Content";
import React from 'react';
import {Footer, Header, Navigation} from "./partial/Navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faChartLine,
    faCircleHalfStroke,
    faDiagramSuccessor,
    faSignature
} from "@fortawesome/free-solid-svg-icons";

const LandingPage: React.FC = () => {
    return (
        <Layout>
            <Header subtitle={"Distributed Computation & Tooling"}/>
            <Page Grow className="app-main">
                <Space direction={"vertical"} Full justify={"space-around"} align={"center"}>
                <Content className={"limits pad-content wide"}>
                    <Row Gap>


                        <Col xs={24} md={12}>
                            <Space justify={"space-around"} Full direction={"vertical"}>


                                <Space GapSm direction={"vertical"} className={"huge"}>

                                    <Title Large><strong>Inference</strong>, <strong>Retrieval</strong>, and <strong>Augmented Generation</strong></Title>
                                    <Title>Natural Language Understanding</Title>
                                    <Divider/>
                                    <p>
                                        All features are accessible via our intuitive online interface and API
                                        endpoints. Take
                                        your business to the next level with scalable, AI-powered solutions.
                                    </p>
                                </Space>

                            </Space>
                        </Col>


                        <Col xs={24} md={12} className={"pattern02 card rounded"}>
                            <Space direction="vertical" Full align={"center"} justify={"center"}>
                                <Content Pad>
                                    <Space direction={"vertical"} Full Gap className={"huge"}>
                                        <Title><strong>AI Powered Insights</strong></Title>
                                        <Paragraph style={{color: "white"}}>Relation Database Integration, Analytics, Secure Private Integrations, Automatic Database Schema & DDL
                                            Mappings, Recommendations & Insights, and much more.</Paragraph>
                                        <Button href={"/databox"} type={"primary"}>Start using Inference & API Endpoints</Button>
                                    </Space>
                                </Content>
                            </Space>
                        </Col>




                        <Col xs={24} lg={24}>
                            <Card Full Pad className={"pattern01 rounded"}>
                                <Row Gap>
                                    <Col xs={24} lg={12}>
                                        <Space direction="vertical" Full GapSm justify={"space-around"}>
                                            <Content>
                                            <Title>Unleash the Power of <strong>ClusterFX</strong></Title>
                                            <Paragraph>
                                                <strong>ClusterFX</strong> revolutionizes distributed computation with cutting-edge APIs and
                                                tooling. Unlock the full potential of your infrastructure with features designed to supercharge
                                                your AI and data solutions.
                                            </Paragraph>
                                            </Content>
                                        </Space>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <ul>
                                            <li><strong>Request Restructuring:</strong> Optimize complex workflows with seamless API calls.</li>
                                            <li><strong>Extensive Debugging Outputs:</strong> Gain deep insights into every request.</li>
                                            <li><strong>Multipart Outputs:</strong> Deliver data as Text, Charts, Tables, or Structures.</li>
                                            <li><strong>Custom Data Alignments:</strong> Align data to meet your unique needs.</li>
                                            <li><strong>Advanced Recommendations:</strong> Get smarter with AI-driven insights.</li>
                                            <li><strong>Private AI Analytics:</strong> Secure multi-tenant analytics tailored for you.</li>
                                            <li><strong>Usage Tracking:</strong> Monitor interactions with API key-based logging.</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Card justify={"space-between"} direction={"vertical"} Pad className={"active"} style={{
                                //background: "#f0f5fa"
                            }}>

                                <Space Gap>
                                    <Title>Get Your Data Working for You</Title>
                                    <p>
                                        Design, Implement, and Augment your existing technology stacks or integrate
                                        directly into greenfield projects. We at <strong>OakFrame Interactive
                                        Ltd</strong> have experience at all
                                        business and application scale to ensure a defined, strategic project and
                                        implementation plan, including lifecycle support, that appropriately fits your
                                        needs.
                                    </p>

                                    <Row Gap>
                                        <Col xs={24} md={12} xl={6}>
                                            <strong>
                                                <Space direction={"horizontal"} align="center" GapSm>
                                                    <div>
                                                        <div className={"border-box"} style={{backgroundColor: "#a4cbea"}}>
                                                            <FontAwesomeIcon fixedWidth size={"2x"} icon={faDiagramSuccessor}/>
                                                        </div>
                                                    </div>
                                                    <h3>Data Enrichment</h3>
                                                </Space>
                                            </strong>
                                            <Divider/>
                                            <Paragraph className="">
                                                Extract <strong>valuable insights</strong> from <em>reviews</em> or <em>social
                                                media posts</em>, enabling <strong>real-time decision-making</strong>.
                                            </Paragraph>
                                        </Col>
                                        <Col xs={24} md={12} xl={6}>
                                            <strong>
                                                <Space direction={"horizontal"} align="center" GapSm>
                                                    <div>
                                                        <div className={"border-box"} style={{backgroundColor: "#a4cbea"}}>
                                                            <FontAwesomeIcon fixedWidth size={"2x"} icon={faChartLine}/>
                                                        </div>
                                                    </div>
                                                    <h3>Predictive Analytics</h3>
                                                </Space>
                                            </strong>
                                            <Divider/>
                                            <Paragraph className="">
                                                Use <strong>historical data</strong> to predict <em>purchase
                                                intent</em> or <em>churn risk</em>, enabling <strong>proactive
                                                strategies</strong>.
                                            </Paragraph>
                                        </Col>
                                        <Col xs={24} md={12} xl={6}>
                                            <strong>
                                                <Space direction={"horizontal"} align="center" GapSm>
                                                    <div>
                                                        <div className={"border-box"} style={{backgroundColor: "#a4cbea"}}>
                                                            <FontAwesomeIcon fixedWidth size={"2x"} icon={faSignature}/>
                                                        </div>
                                                    </div>
                                                    <h3>Content Creation</h3>
                                                </Space>
                                            </strong>
                                            <Divider/>
                                            <Paragraph className="">
                                                Quickly generate <strong>high-quality text</strong>, <em>social
                                                posts</em>, or <em>email content</em> to maintain a strong online
                                                presence.
                                            </Paragraph>
                                        </Col>
                                        <Col xs={24} md={12} xl={6}>
                                            <strong>
                                                <Space direction={"horizontal"} align="center" GapSm>
                                                    <div>
                                                        <div className={"border-box"} style={{backgroundColor: "#a4cbea"}}>
                                                            <FontAwesomeIcon fixedWidth size={"2x"} icon={faCircleHalfStroke}/>
                                                        </div>
                                                    </div>
                                                    <h3>Sentiment Analysis</h3>
                                                </Space>
                                            </strong>
                                            <Divider/>
                                            <Paragraph className="">
                                                Analyze feedback to <strong>understand sentiment</strong> and <strong>refine
                                                strategies</strong> for better customer engagement.
                                            </Paragraph>
                                        </Col>
                                    </Row>


                                    <Space GapSm align={"center"}><Link href={"/databox/models"}>View our Public and Foundational Models</Link>
                                        <FontAwesomeIcon fixedWidth icon={faArrowRight}/>
                                    </Space>
                                </Space>

                            </Card>
                        </Col>


                    </Row>
                </Content>
                </Space>
            </Page>
            <Footer/>
        </Layout>
    );
}

export default LandingPage;
