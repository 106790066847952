import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Col,
    Content,
    Layout,
    Page,
    Paragraph,
    Pill,
    Row,
    Separator,
    Space,
    Title
} from "../layout/Content";
import {useNavigate, useParams} from "react-router-dom";
import {
    faArrowUpFromBracket, faBolt, faChartBar,
    faDiagramNext, faFire, faLock,
    faPlus, faShare, faSplotch, faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PaginatedCollectionList from "./PaginatedCollectionList";
import MLModelConnectorForm from "./MLModelConnectorForm";
import DatasourceConnectorForm from "./DatasourceConnectorForm";
import {useUserProfileContext} from "../../lib/UserContext";


const renderPostItem = (post: any) => {

    const vis = (type: string) => {
        return (

            <Card Pad Wide>
                <Space direction={"vertical"} GapSm>
                <Content>
                    <Title>{post.name || 'Unknown'}</Title>
                    <Space GapSm>
                    <Paragraph><FontAwesomeIcon className={`icon ${post.options?.public?"muted-heavy":"default"}`} icon={post.options?.public?faUsers:faLock} fixedWidth/> {post.options?.public?"Public":"Private"}</Paragraph>
                        <Separator/>
                    <Paragraph><FontAwesomeIcon className={`icon ${post.is_external?"muted-heavy":"success"}`} icon={post.is_external?faShare:faSplotch} fixedWidth/> {post.is_external?<strong>External</strong>:<strong>cFX Internal</strong>} {post.model_task==="moderation"?"Moderation":post.is_instruct_model?"Instruct":"Completion"} Model</Paragraph>
                    </Space>

                </Content>

                    {post.description&&<Paragraph>{post.description}


                    </Paragraph>}

                <Row GapSm>
                    <Col xs={12} md={6}>
                        <Space direction={"vertical"}>
                            <Paragraph><strong className={"muted"}>Model Name</strong></Paragraph>
                            <Paragraph>{post.configuration?.model || 'Unknown Model'}</Paragraph>
                        </Space>
                    </Col>
                    <Col xs={12} md={6}>
                        <Space direction={"vertical"}>
                            <Paragraph><strong className={"muted"}>Model Provider</strong></Paragraph>
                            <Paragraph>{post.type || 'Unknown'}</Paragraph>
                        </Space> </Col>
                    <Col xs={12} md={6}>
                        <Space direction={"vertical"}>
                            <Paragraph><strong className={"muted"}>Model Task</strong></Paragraph>
                            <Paragraph>{post.model_task || 'Unknown Task'}</Paragraph>
                        </Space>
                    </Col>

                    <Col xs={12} md={6}>
                        <Space direction={"vertical"}>
                            <Paragraph><strong className={"muted"}>Moderation</strong></Paragraph>
                            <Paragraph><FontAwesomeIcon className={`icon ${post.requires_moderation?"muted-heavy":"default"}`} icon={post.requires_moderation?faBolt:faFire} fixedWidth/> {post.requires_moderation?"Enforced":"Uncensored"}</Paragraph>
                        </Space>
                    </Col>
                    {/*<Col xs={24}><textarea>{JSON.stringify(post)}</textarea></Col>*/}
                    {/*<Col xs={12} md={4}>
                        <strong>Requires Moderation</strong>
                        <Paragraph>{post.requires_moderation ? 'Yes' : 'No'}</Paragraph>
                    </Col>
                    <Col xs={12} md={4}>
                        <strong>Instruct Model</strong>
                        <Paragraph>{post.is_instruct_model ? 'Yes' : 'No'}</Paragraph>
                    </Col>
                    <Col xs={12} md={4}>
                        <strong>External Model</strong>
                        <Paragraph>{post.is_external ? 'Yes' : 'No'}</Paragraph>
                    </Col>*/}
                </Row>
                </Space>
                </Card>
        );
    };

    return (vis(post.type)
    );
}


const MLModel: React.FC = () => {

    const {page} = useParams<{ page: string }>();
    const {controller} = useParams<{ controller: string }>();
    const {uuid} = useParams<{ uuid: string }>();
    const {queryUserProfile, getUserProfile, setUserProfile, hasQueriedUserProfile} = useUserProfileContext();
    const user = getUserProfile();
    const [dataSource, setDataSource] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const navigate = useNavigate();

    // Fetch fine-tune data by UUID
    useEffect(() => {
        const fetchFineTune = async () => {
            if (controller === "edit" && uuid) {
                try {
                    const response = await fetch(`https://api.clusterfx.org/model/${uuid}`, {
                  //      mode: 'cors',
                        credentials: 'include',
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });

                    if (response.ok) {
                        const result = await response.json();
                        if (result.results.length > 0) {
                            setDataSource(result.results[0]);
                        }
                    }
                } catch (error) {
                    console.error("Failed to fetch model:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchFineTune();
    }, [controller, uuid]);

    const btn = () => {
        return <Button type={"primary"} onClick={() => {
            navigate("/databox/models/add");
        }}><FontAwesomeIcon icon={faPlus} fixedWidth/>Add New Model</Button>;
    };

    if (controller === "add") {
        return <Content className={"limits wide pad-content full"}>
            <MLModelConnectorForm/>
        </Content>;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (controller === "edit" && dataSource && user && dataSource.owner.uuid === user.uuid) {
        return <MLModelConnectorForm value={dataSource}/>;

    }


    return (
        <Content className={"limits wide pad-content full"}>
            <Space justify="space-between" direction="vertical" GapSm Full Wide>
                <Space direction="vertical" GapSm>

                    <Space Wide Gap justify={"space-between"} align={"center"}>
                        <Title>Models</Title>{btn()}
                    </Space>

                    <PaginatedCollectionList
                        selectedCollection="model"
                        renderItem={renderPostItem}
                        actions={hasQueriedUserProfile() && getUserProfile() ? [
                            {element: <FontAwesomeIcon icon={faArrowUpFromBracket} fixedWidth/>}
                        ] : undefined}
                    />


                </Space>

            </Space>
        </Content>
    );

};

export default MLModel;
