import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Button, Card, Input, Link, Pill
} from "./layout/Content";
import React from 'react';
import {Footer, Header, Navigation} from "./partial/Navigation";
import {
    faBookBookmark,
    faCircleNodes,
    faCodeBranch, faDatabase,
    faGear,
    faKey,
    faMicrochip, faNetworkWired, faPlus, faSliders,
    faThList, faTurnUp, faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LoginModal} from "./part/LoginModal";
import {useUserProfileContext} from "../lib/UserContext";
import DatasourceConnectorForm from "./partial/DatasourceConnectorForm";
import {useParams} from "react-router-dom";
import Datasource from "./partial/Datasource";
import Inference from "./partial/Inference";
import {RotateProp} from "@fortawesome/fontawesome-svg-core";
import DataBoxDocs from "./partial/DataBoxDocs";
import MLModel from "./partial/MLModel";
import FineTune from "./partial/FineTune";
import InteractiveSearchPage from "./partial/InteractiveSearchPage";

const DataBox: React.FC = () => {

    const {page} = useParams<{ page: string }>();


    return (
        <Layout>
            <Header subtitle={"Inference & API Endpoints"}/>
            <Page Grow className="app-main">


                {!(page || page === "") && <Inference/>}

                {page === "sources" && <Datasource/>}

                {page === "docs" && <DataBoxDocs/>}


                {page === "explorer" && <InteractiveSearchPage/>}

                {page === "models" && <MLModel/>}

                {page === "tuning" && <FineTune/>}

            </Page>
            <Footer/>
        </Layout>
    );
}

export default DataBox;
