import {
    Col,
    Content,
    DropDown,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Spacer,
    Title,
    Divider,
    Button, Card, Input
} from "../layout/Content";
import React from "react";
import {Footer} from "../partial/Navigation";
import {Header} from "../partial/Navigation";

export function CookiePolicy() {
    return (<Layout className="landing-page">
        <Header/>
        <Page Grow>
            <Content style={{height: "100%"}}>
                <div className={"limits pad"}>
                <Row>
                    <Col xs={24}>
                        <Space direction="vertical" Gap>

                            <Title Large>Cookie Policy</Title>
                            <p>We use cookies to improve your experience on the Peek. This Cookie Policy explains what cookies are, how we use them, and your choices regarding cookies.</p>
                            <ul>
                                <li>Cookies are small text files stored on your device by your browser. They help us recognize you and remember your preferences.</li>
                                <li>We use both session cookies (which expire when you close your browser) and persistent cookies (which remain on your device until deleted) to provide a personalized experience.</li>
                                <li>We use cookies for authentication, security, performance, and analytics purposes.</li>
                                <li>You can manage your cookie preferences through your browser settings. However, disabling cookies may affect your ability to use certain features of our platform.</li>
                                <li>For more information about our use of cookies, please contact us at our Parent Website.</li>
                            </ul>

                        </Space></Col></Row>
                </div>
            </Content>
        </Page>
        <Footer/>
    </Layout>)
}