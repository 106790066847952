import React, { useEffect, useRef, useState } from 'react';
import { getNickname } from './DatasourceConnectorForm';
import {Button, Card, Col, Content, Input, Paragraph, Pill, Row, Space} from '../layout/Content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
// @ts-ignore
import MarkdownRenderer from 'react-markdown-renderer';
import {Collapsible,Collapsible2, Empty, Select, Tooltip} from '../layout/Defaults';
import SyntaxHighlighter from "react-syntax-highlighter";
import {github, githubGist, xcode} from "react-syntax-highlighter/dist/esm/styles/hljs";
import {
    faAngleDown, faAngleRight,
    faFlask, faList,
    faRobot, faSpinner,
    faTable,
    faTableCellsRowUnlock,
    faTriangleExclamation, faUsers
} from "@fortawesome/free-solid-svg-icons";
import Badge from "../part/Badge";
import DataCard, { StatsDetails } from "./DataCard";
import {useUserProfileContext} from "../../lib/UserContext";
import Tree from "./Tree";
import {SelectionCard} from "./SelectionCard";

export const renderResponse = (data: any) => {
    const { alignment } = data || {};

   // console.log("a ", alignment);

    if (!alignment) {
        return 'Invalid data received.';
    }

    if (alignment.intent === "question"){
        return <Content><Paragraph>{data.content}</Paragraph></Content>;
    }

 //   return <span>{JSON.stringify(data.response)}</span>

    let result = data.data;
    if (Array.isArray(result)){
        result = data.data[data.data.length-1] || [];
    }


    const intent = () => {
        if (data){
           /* try {
                return <Space GapSm direction={"vertical"}>
                    <MarkdownRenderer markdown={(alignment.explanation||result?.content||data.content||"")} options={{preset:"full"}} />
                    <DataCard incoming={data} response={alignment}/>
                </Space>
            }catch (e) {
                console.log(e);
            }*/
        }

        switch (alignment.intent) {
            case 'question':
                return <Paragraph>{data.content}</Paragraph>;
            case 'answer':
            case 'series':
                return <DataCard incoming={data} response={alignment}/>;//`Answer: ${JSON.stringify(data.data)}`;
            case 'table':
                return renderTable(result);
            case 'list':
                return renderList(result);
            case 'graph':
                return 'Graph rendering is not implemented yet.';
            default:
                return `Unknown intent: ${alignment.intent} ${JSON.stringify(data.content||result)}`;
        }
    };
// <span>{intent()}</span>
    return (
        <Space GapSm direction={"vertical"}>
            <Space direction={"vertical"
            } GapSm>
                <MarkdownRenderer markdown={(data.inference?.explanation||alignment.explanation||result?.content||data.content||"")} options={{preset:"full"}} />
                <div className={"markdown-viewer"} style={{flex: "1 0 auto"}}>{intent()}</div></Space>
            {data.stats&&<small><StatsDetails stats={data.stats}/></small>}
        </Space>
    );
};

const getCurl = (sources:any[], selectedSource:string) =>{
    return `# clusterfx.org inference ${getNickname(sources?.filter((s)=>{return s.uuid === selectedSource})[0])}\ncurl -X POST ​https://api.clusterfx.org/​actions/​datasource/​inference/​${selectedSource} \n-H "Authorization: Bearer a01b02-your-api-key-c03d05" \n-d '{{"prompt": "${prompt}","options":{"alignment":true}}}'`;
}

const renderTable = (results: any[]) => {
    if (results.length === 0) return 'No results to display.';
    const headers = Object.keys(results[0]);

    return (
        <table>
            <thead>
            <tr>
                {headers.map((header) => (
                    <th key={header}>{header}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {results.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    {headers.map((header) => (
                        <td key={`${rowIndex}-${header}`}>{row[header]}</td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
};


export interface Message {
    role: string;
    name?: string;
    content: string;
}


interface UIMessage {
    sender: string;
    content: {
        alignment: {
            intent: string;
            explanation?:string;
            natural_language?:string;
            query?:string;
        };
        [key: string]: any; // Additional properties for content
    };
}

interface MessageCardProps {
    message: UIMessage;
}

const MessageCard: React.FC<MessageCardProps> = ({ message }) => {
    // Create a copy of the message to safely modify its content
    const [modifiedMessage, setModifiedMessage] = useState({ ...message });
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const handleIntentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newIntent = e.target.value;
        // Update the intent in the copy of the message
        const newState = {...message};
        newState.content.alignment.intent = newIntent;
        setModifiedMessage(prevState => ({

            ...prevState,
            content: {
                ...prevState.content,
                    ...newState.content
            }
        }));
    };

    return (
        <Card Pad>
            <Content>
            <Space GapSm direction={"vertical"}>
                <Space Wide GapSm justify={"space-between"}>
                    <strong>{modifiedMessage.sender}<span className={"muted-heavy"}>:</span></strong>
                    <select value={modifiedMessage.content.alignment?.intent||"answer"} onChange={handleIntentChange}>
                        <option value="answer">Answer</option>
                        <option value="suggestion">Suggestion</option>
                        <option value="series">Series</option>
                        <option value="list">List</option>
                        <option value="table">Table</option>
                        <option value="chart">Chart</option>
                        <option value="longform">Longform</option>
                        <option value="reference">Reference</option>
                    </select>
                </Space>
                {modifiedMessage.content && renderResponse(modifiedMessage.content)}
                <Collapsible2 title={<Paragraph>Response Debug Details</Paragraph>}>
                    <Content PadSm>
                    <Tree data={modifiedMessage}/>
                    </Content>
                </Collapsible2>
            </Space>
            </Content>
        </Card>
    );
};


const renderList = (results: any[]) => (
    <ul>
        {results.map((item, index) => (
            <li key={index}>{JSON.stringify(item)}</li>
        ))}
    </ul>
);

const Inference: React.FC = () => {

    const {queryUserProfile, getUserProfile, hasQueriedUserProfile} = useUserProfileContext();

    const [prompt, setPrompt] = useState<string>('');
    const [messages, setMessages] = useState<Array<{ sender: string, content: any }>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [sources, setSources] = useState<any[] | null>(null);
    const [selectedSource, setSelectedSource] = useState<string | null>(null);
    const [models, setModels] = useState<any[] | null>(null);
    const [temperature, setTemperature] = useState<number>(0.8);
    const [selectedModel, setSelectedModel] = useState<string | null>(null);
    const [selectedModerationModel, setSelectedModerationModel] = useState<string | null>(null);
    const [selectedInferenceModel, setSelectedInferenceModel] = useState<string | null>("");

    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    const getSource = (source:string)=>{
        if (!sources || !source){
            return false;
        }
        return sources?.find((a:any)=>{return a.uuid===source});
    }

    const getModel = (source:string)=>{
        if (!models || !source){
            return false;
        }
        return models?.find((a:any)=>{return a.uuid===source});
    }


    const isDataPrivacyError = (model?:string)=>{
        if (!selectedSource || !model){
            return false;
        }
       // if (!model && models){
        //    return models.map((model)=>{ return (model.is_external && selectedSource && getSource(selectedSource)?.dataPrivacyType>=2 ) }).filter((a:boolean)=>{return a===true}).length > 0;
       // }
        return (getModel(model).is_external && selectedSource && getSource(selectedSource)?.dataPrivacyType>=2 )
    }

    useEffect(() => {
        const fetchDataSources = async () => {

            try {
                const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/datasource`, {
                    method: 'GET',
                    //mode: 'cors',
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: any = await response.json();
                setSources(data.results);
                setSelectedSource(data.results[0]?.uuid || null); // Set the first source as default
            } catch (err) {
                console.error(err);
            }

        };
        const fetchModels = async () => {

            try {
                const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/model`, {
                    method: 'GET',
                   // mode: 'cors',
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: any = await response.json();
                setModels(data.results);
                setSelectedModel(data.results?.sort((a:any, b:any)=>{
                        return (a.model_task!=="moderation"?-1:1) -(b.model_task!=="moderation"?-1:1)
                })[0]?.uuid || null); // Set the first source as default
            } catch (err) {
                console.error(err);
            }

        };
        fetchDataSources();
        fetchModels();
    }, []);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSend = async () => {
        if (!prompt.trim() || !selectedSource) {
            return;
        }

        const history:Message[] = messages.map((m:UIMessage)=>{

            if (m.sender==='user'){
                return {
                    role:m.sender,
                    content:m.content.content
                }
            }

            return {
                role:m.sender,
                content:JSON.stringify({
                    explanation:m.content.alignment?.explanation||"",
                    natural_language:m.content.alignment?.natural_language||"",
                    query:m.content.alignment?.query||"",
                })
            }

        });

        console.log("HISTORY",history);


        setMessages((prevMessages) => [...prevMessages, { sender: 'user', content: {content:prompt, alignment:{intent:"question"}} }]);

        const p = prompt+"";
        setPrompt(''); // Clear the input



        try {
            setLoading(true);
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/actions/datasource/inference/${selectedSource}`, {
                method: 'POST',
                //mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}` // Add your API key here
                },
                body: JSON.stringify({ prompt:p, model:selectedModel, moderation:selectedModerationModel, options:{inference:selectedInferenceModel, temperature}, history })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            console.log("DATA FROM", data);
            // @ts-ignore
            setMessages((prevMessages) => [...prevMessages, { sender: 'assistant', content: (data) }]);

        } catch (error: any) {
            console.error('Error fetching data:', error.message);
            setMessages((prevMessages) => [...prevMessages, { sender: 'assistant', content: false }]);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSend();
        }
    };

    const user = getUserProfile();

    return (
        <Content className={"limits wide pad-content full stretch"} >


        <Space Gap direction={"vertical"} Full NoWrap Wide>
            <Space GapSm direction={"vertical"} NoWrap Wide>

               <Space GapSm align={"end"}>
                   <Space direction={"vertical"} GapSm>{sources && (
                    <Select
                        defaultValue={sources[0]?.uuid||""}
                        options={(!sources||sources.length===0)?[{
                            value: undefined,
                            element: (
                                <Space className={"disabled"} align="center" GapSm NoWrap>
                                    <FontAwesomeIcon className={"warning"} icon={faTriangleExclamation} fixedWidth={true} />
                                    <span>No Sources Available</span>
                                </Space>
                            )
                        }]:sources.map((source) => ({
                            value: source.uuid,
                            element: (
                                <Space align="center" GapSm NoWrap>
                                    <FontAwesomeIcon icon={faTable} fixedWidth={true} className={"muted-heavy"}/>
                                    {(!user || (user && user.uuid !== source.owner?.uuid)&&source.options.public)&&<Tooltip message={"Public / Community Data Source"}><FontAwesomeIcon icon={faUsers} fixedWidth={true} className={"muted-heavy"}/></Tooltip>}
                                    <span>{source.nickname || getNickname(source) || "Unknown Source"}</span>
                                </Space>
                            )
                        }))}
                        onSelected={(value: string) => setSelectedSource(value)}
                    />
                   )}</Space>
                <Input
                    grow
                    type="text"
                    value={prompt}
                    disabled={loading}
                    onChange={(e) => setPrompt(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type your message here..."
                />
                <Button
                    onClick={handleSend}
                    type={(loading||isDataPrivacyError(selectedModel||""))?"disabled":"primary"}
                >
                    Send
                </Button>
               </Space>
                <Collapsible2 title={<Paragraph>Advanced Options</Paragraph>} >
                    <Content PadSm>
               <Space GapSm align={"stretch"}>




                   {(models&&models.length>0)&&<Space direction={"vertical"} GapSm> <strong>Moderation Model</strong>{models && (
                    <Space><Select
                        placeholder={<Paragraph><Space GapSm NoWrap align={"center"}><FontAwesomeIcon fixedWidth icon={faList} /><span>Select a Moderation Model</span></Space></Paragraph>}
                        type={isDataPrivacyError(selectedModerationModel||"")?"danger":undefined}
                        categorize={{
                            property: "model_task",
                            order: ["moderation", "instruct", "completion", "alignment", "results"]
                        }}
                        defaultValue={selectedModerationModel||""}
                        options={models.map((model) => ({
                            category:model.model_task,
                            value: model.uuid,
                            element: (
                                <Paragraph><Space align="center" GapSm>
                                    {(!user || (user && user.uuid !== model.owner?.uuid)||model.options?.public)&&<Tooltip message={"Public / Community Model"}><FontAwesomeIcon icon={faUsers} fixedWidth={true} className={"muted-heavy"}/></Tooltip>}
                                    {isDataPrivacyError(model.uuid)&&<Tooltip message={"External Model cannot be used with Data Source Privacy Setting"}><FontAwesomeIcon icon={faTriangleExclamation} fixedWidth={true} /></Tooltip>}
                                    {model.name || "Unknown Model"}
                                </Space></Paragraph>
                            )
                        }))}
                        onSelected={(value: string) => setSelectedModerationModel(value)}
                    /></Space>
                )}</Space>}

                   {(models&&models.length>0)&& <Space direction={"vertical"} GapSm> <strong>Inference Model</strong>{models && (
                    <Select
                        type={isDataPrivacyError(selectedModel||"")?"danger":undefined}
                        defaultValue={selectedModel||models[0].uuid}
                        categorize={{
                            property: "model_task",
                            order: [ "instruct", "completion", "alignment","moderation", "results"]
                        }}
                        options={models.map((model) => ({
                            category:model.model_task,
                            value: model.uuid,
                            element: (
                                <Paragraph><Space align="center" GapSm>
                                    {(!user || (user && user.uuid !== model.owner?.uuid)||model.options?.public)&&<Tooltip message={"Public / Community Model"}><FontAwesomeIcon icon={faUsers} fixedWidth={true} className={"muted-heavy"} /></Tooltip>}
                                    {isDataPrivacyError(model.uuid)&&<Tooltip message={"External Model cannot be used with Data Source Privacy Setting"}><FontAwesomeIcon icon={faTriangleExclamation} fixedWidth={true} /></Tooltip>}
                                    {model.name || "Unknown Model"}
                                </Space></Paragraph>
                            )
                        }))}
                        onSelected={(value: string) => setSelectedModel(value)}
                    />
                )}</Space>}

                   <Space direction={"vertical"} GapSm NoWrap>
                       <strong>Inferred Understanding</strong>
                      <Space GapSm NoWrap> <SelectionCard
                           title="Off"
                           isSelected={selectedInferenceModel === ""}
                           onClick={() => setSelectedInferenceModel("")}
                       />
                       <SelectionCard
                           title="On"
                           isSelected={selectedInferenceModel === "true"}
                           onClick={() => setSelectedInferenceModel("true")}
                       /></Space>
                   </Space>

                   <Space direction={"vertical"} GapSm NoWrap>
                       <strong>Temperature</strong>
                      <input type={"number"} placeholder={"0.8"} step={0.1} min={0} max={2} value={temperature} onChange={(e)=>{
                          setTemperature( parseFloat(e.target.value||"0.8"));
                      }}/>
                   </Space>

               </Space></Content>
                </Collapsible2>
            </Space>

            <Space style={{ border: '1px solid #ccc', overflowY: 'auto', height:"100%",maxHeight:"100%" }} Full Grow>
                <Content PadSm>
                {(!messages||messages.length==0)&&<Empty description={"Start with a Query about your Data"}/>}
                <Space GapSm direction={"vertical"}>
                    {messages.map((message, index) => (
                        <MessageCard message={message} />
                    ))}
                    {loading && <Card PadSm><Space align={"center"} justify={"center"}><Space GapSm align={"center"}><FontAwesomeIcon icon={faSpinner} spinPulse /><em className={"muted"}>Processing</em></Space></Space></Card>}
                    <div ref={messagesEndRef} />
                </Space>
                </Content>
            </Space>
        </Space>
        </Content>
    );
};

export default Inference;
