// Reusable SelectionCard Component
import React from "react";
import {Card, Paragraph, Pill, Space, Title} from "../layout/Content";

interface SelectionCardProps {
    title: string;
    description?: string;
    isSelected: boolean;
    onClick: () => void;
    details?: React.ReactNode[];
    pills?: string[];
    disabled?: boolean;
}

export const SelectionCard: React.FC<SelectionCardProps> = ({
                                                         title,
                                                         description,
                                                         isSelected,
                                                         onClick,
                                                         details,
                                                         pills,
                                                         disabled
                                                     }) => (
    <Card PadSm className={disabled ? "disabled" : (isSelected ? "active" : "")} onClick={disabled ? () => { } : onClick}>
        <Space Gap direction={"vertical"}>
            <Space Gap align={"center"}>
                <input type="checkbox" checked={isSelected} disabled={disabled} />
                <Paragraph>{title}</Paragraph></Space>
            {description&&<small>{description}</small>}
            {details&&<Space GapSm Wide direction={"vertical"}>
                {details.map((detail, idx) => (
                    <span>{detail}</span>
                ))}
            </Space>}
            {pills && (
                <Space GapSm direction={"vertical"}>
                    {pills.map((pill, idx) => (
                        <Pill key={idx}>{pill}</Pill>
                    ))}
                </Space>
            )}
        </Space>
    </Card>
);