import React from 'react';
import {Card, Divider, Paragraph, Space, Title} from "../layout/Content";
import {ResponsiveLine} from "@nivo/line";

interface FieldMapping {
    column_name: string;
    render_type: 'primary' | 'value' | 'attribute' | 'timestamp' | 'composite';
    label: string;
    value_unit: string;
    description: string;
    composite_fields: string[];
    time_series_format: {
        time_column: string;
        value_columns: string[];
        granularity: string;
    };
}
interface ChartComponentProps {
    data: any[];
    response: DataCardResponse;
}

const ChartComponent: React.FC<ChartComponentProps> = ({ data, response }) => {

    const { time_series_format } = response;
    if (!time_series_format || !time_series_format.time_column || !time_series_format.value_columns) {
        console.log('No details', time_series_format);
        return null;
    }

    const timeColumn = time_series_format.time_column;
    const valueColumns = time_series_format.value_columns;

    const formatTimeValue = (value: any): string | number | Date => {
        // Handle cases where timeColumn values may vary in format
        if (value.toString().length === 4 && (parseInt(value).toString() ===value)) {
            // Assume it's a year
            return value;
        } else if (typeof value === 'number') {
            // Assume it's a timestamp (convert to ISO)
            return (value);
        }else if (typeof value === 'string') {


            if ((!isNaN(Date.parse(value))) &&  value.length >= 10) {
                // ISO date or valid date string
                return new Date(value);
            }
            if (!isNaN(parseInt(value))){
                return parseInt(value);
            }
            // Assume it's a category or plain string (return as-is for Nivo support)
            return value;
        }
        // Return as-is for unsupported types (shouldn't typically occur)
        return value;
    };

    // Prepare the data for Nivo Line chart
    const series:any[] = valueColumns.map((valueColumn) => ({
        id: valueColumn,
        data: data.map((item: any) => ({
            x: formatTimeValue(item[timeColumn]), // Validate and format the x-axis value
            y: parseFloat(item[valueColumn]) || 0, // Ensure y-axis value is numeric
        })),
    }));

    const xFormat = (series[0]?.data[0]?.x instanceof Date) ? 'time' : 'point';

    console.log(series[0].data[0],xFormat);

    return (
        <div style={{ height: '400px' }}>
            <ResponsiveLine
                data={series}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={(xFormat==="time")?{ type: 'time', format: "%Y-%m-%dT%H:%M:%S.%L%Z", precision: 'day' }:undefined}
             //   xFormat="time:%Y-%m-%d"
                yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
                axisLeft={{
                    legend: 'Value',
                    legendOffset: -40,
                    legendPosition: 'middle',
                }}
                axisBottom={{
                    format: (xFormat==="time")?'%b %d':undefined,
                    legend: 'Time',
                    legendOffset: 36,
                    legendPosition: 'middle',
                }}
                pointSize={10}
                pointBorderWidth={2}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
};



interface DataCardResponse {

        content: any;
        intent: string;
        field_mappings: FieldMapping[];
        time_series_format: {
            time_column: string,
            value_columns: string[],
            granularity: string
        }

}

interface DataCardProps {
    incoming: any;
    response: DataCardResponse
}

const DataCard: React.FC<DataCardProps> = ({incoming, response}) => {
    const fields = response.field_mappings || [];
    const data:any[] = incoming.data[0] || [];


    //console.log("response", response, data);
    // Function to render an SVG chart for time series data



    if (incoming.alignment  && incoming.alignment.intent && incoming.alignment.intent === "series") {
        return (
            <div>
                <h3>{incoming.alignment.intent}</h3>
                {data&&<ChartComponent data={data} response={response} />}
            </div>
        );
    }

    return (
        <Space GapSm>

            {data.map((item: any, rowIndex: number) => {
                const fieldMappings = fields.map(field => ({
                    ...field,
                    value: item[field.column_name].toString() || '',
                }));

                // Get the primary type item for the card title
                const primaryItem = fieldMappings.find(fm => fm.render_type === 'primary')||fieldMappings[0];

                return (
                    <Card PadSm key={rowIndex}>
                        <Space direction="vertical">
                           <Paragraph> {primaryItem ? (
                                <span>
                                    {primaryItem.label}:
                                    <Title>
                                      <strong>{primaryItem.value}</strong>
                                    </Title>
                                </span>
                            ) : (
                                'Unknown Title'
                           )}</Paragraph>
                            {primaryItem && primaryItem.description && <Paragraph><small>{primaryItem.description}</small></Paragraph>}

                            {/* Render value entries */}
                            {fieldMappings
                                .filter(fm => fm.render_type === 'value')
                                .map((fm, valueIndex) => (
                                   <Paragraph> <Space key={valueIndex} justify="space-between">
                                        <span>
                                            <strong>{fm.label}:</strong></span>
                                        <span>
                                            {fm.value.toString()} {fm.value_unit}
                                        </span>
                                    </Space></Paragraph>
                                ))}

                            {/* Render attributes */}
                            <Divider/>
                            {fieldMappings
                                .filter(fm => fm.render_type === 'attribute')
                                .map((fm, attributeIndex) => (
                                    <Paragraph key={attributeIndex}>
                                        <strong>{fm.label}:</strong> {fm.value.toString()} {fm.value_unit}
                                    </Paragraph>
                                ))}

                            {/* Render timestamp */}
                            {fieldMappings
                                .filter(fm => fm.render_type === 'timestamp')
                                .map((fm, timestampIndex) => (
                                    <Paragraph key={timestampIndex}>
                                        <strong>{fm.label}:</strong> {new Date(fm.value).toLocaleDateString()} {fm.value_unit}
                                    </Paragraph>
                                ))}

                            {/* Render composite fields */}
                            {fieldMappings
                                .filter(fm => fm.render_type === 'composite')
                                .map((fm, compositeIndex) => (
                                    <Paragraph key={compositeIndex}>
                                        <strong>{fm.label}:</strong> {fm.composite_fields.map(cf => item[cf]).join(', ')} {fm.value_unit}
                                    </Paragraph>
                                ))}
                        </Space>
                    </Card>
                );
            })}
        </Space>
    );
};


export const StatsDetails: React.FC<{ stats: any }> = ({stats}) => {

    return (<Card className={"muted default"} PadSm><Space direction={"vertical"}>
       <Paragraph> <Space GapSm align={"center"}><strong>Inference Timing</strong>
            <span>{(stats?.timing?.moderation || 0).toFixed(2)}ms Moderation</span> <span className={"muted"}>|</span>
            <span>{(stats?.timing?.alignment || 0).toFixed(2)}ms Alignment</span><span className={"muted"}>|</span>
            <span>{(stats?.timing?.inference || 0).toFixed(2)}ms Inference</span><span className={"muted"}>|</span>
            <span>{(stats?.timing?.query || 0).toFixed(2)}ms Query</span><span className={"muted"}>|</span>
            <span>{(stats?.timing?.results || 0).toFixed(2)}ms Results</span>
        </Space>
        <Space GapSm align={"center"}><strong>Token Usage</strong> <span>{stats?.usage?.input_tokens} Input</span><span className={"muted"}>|</span>
            <span>{stats?.usage?.output_tokens} Output</span> </Space></Paragraph>
    </Space></Card>);

}

export default DataCard;
