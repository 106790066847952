import {useNavigate} from "react-router-dom";
import React from "react";
import {Col, Content, Link, Paragraph, Row, Space, Title} from "../layout/Content";
import {InlineImage} from "../layout/Defaults";
import {Menu} from "../Generated";
import {Trademark} from "../part/Trademark";
import {isDarkMode} from "../../lib/Wrapper";

export const Navigation = () => {
    const navigate = useNavigate();

    return (<Space justify={"space-between"} style={{width: "100%"}}>

        <Space Wrap>
            <Link className={window.location.pathname === "/" ? "active" : ""} href={"/"} onClick={() => {
                navigate("/");
            }}>Home</Link>
            <Link className={window.location.pathname === "/articles" ? "active" : ""} href={"/articles"}
                  onClick={() => {
                      navigate("/articles");
                  }}>Articles</Link>
            <Link href={"#"}>Work</Link>
            <Link href={"#"}>APIs & Documentation</Link>
        </Space>
        <Space>
            <Link href={"#"}>Sign In</Link>
        </Space>
    </Space>);
}


export const Footer = () => {


    return (<footer className="app-footer">
        <Content>
            <Row>
                <Col xs={24}>
                    <div className="footer wide limits">
                        <Paragraph>Copyright 2023&mdash;{new Date().getFullYear()} <strong><em>ClusterFX</em></strong><span className={"hide-sm"}> by <strong>OakFrame Interactive Ltd.</strong></span></Paragraph>
                        <Space GapSm align={"center"}>
                            <Link href={"/info/privacy-policy"} className={"small"}>Privacy Policy</Link> <Paragraph
                            Muted>|</Paragraph>
                            <Link href={"/info/terms-of-service"} className={"small"}>Terms of Service</Link> <Paragraph
                            Muted>|</Paragraph>
                            <Link href={"/info/cookie-policy"} className={"small"}>Cookie Policy</Link>
                        </Space>
                    </div>
                </Col>
            </Row>
        </Content>
    </footer>);
}


export const Header: React.FC<{ subtitle?: React.ReactNode }> = ({subtitle}) => {


    return (<header className="app-header">
        <Row>
            <Col xs={24}>
                <div className={"wide limits"}>

                    <Space NoWrap justify={"space-between"}>

                            <Title>
                                <Space align={"center"} GapSm><Link span href={"/"}><Space align={"center"} GapSm><InlineImage src={`/logo.png`}/> <span><strong>ClusterFX</strong><Trademark/></span></Space></Link> <span
                                    className={"hide-sm muted-heavy"}>{subtitle}</span></Space>
                            </Title>


                        <Menu button key={window.location.href}/>
                    </Space>

                </div>
            </Col>
        </Row>
    </header>);
}

