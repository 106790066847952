export function getPlatform(): 'any' | 'pc mac' | 'pc windows' | 'pc linux' | 'mobile ios' | 'mobile android' | 'tablet' | 'console' {
    const ua = navigator.userAgent;
    const platform = navigator.platform;

    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
        return 'mobile ios';
    } else if (/android|Android/.test(ua) && /mobile|Mobile/.test(ua)) {
        return 'mobile android';
    } else if (/android|Android/.test(ua) || /Macintosh/.test(ua) && 'ontouchend' in document || ((platform === 'MacIntel' && navigator.maxTouchPoints > 0) || platform === 'iPad')) {
        return 'tablet';
    } else if (/CrOS/.test(ua) || /TV|PlayStation|Xbox/.test(ua)) {
        return 'console';
    } else if (/Mac/i.test(platform)) {
        return 'pc mac';
    } else if (/Win/i.test(platform)) {
        return 'pc windows';
    } else if (/Linux/i.test(platform)) {
        return 'pc linux';
    } else {
        return 'any'; // Default to PC (Windows) if no match
    }
}

export function getWrapper(): 'browser' | 'pwa' | 'standalone' {
    // @ts-ignore
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) {
        return 'pwa';
    } else if (typeof process !== 'undefined' && process.versions && process.versions.electron) {
        return 'standalone';
    } else {
        return 'browser';
    }
}

export function isDarkMode(){
   return (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
}


export function getVersion(): string {
    const now = new Date();
    const year = now.getFullYear().toString().substr(2);
    const month = ('0' + (now.getMonth() + 1)).slice(-2);
    const day = ('0' + now.getDate()).slice(-2);
    return `${year}.${month}.${day}`;
}