import React, { useEffect, useState } from "react";
import {
    Button,
    Content,
    Paragraph,
    Space,
    Title,
    Input,
    Page,
} from "../layout/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faTriangleExclamation, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Empty, Loading, Pagination, Select, Tooltip } from "../layout/Defaults";
import { getNickname } from "./DatasourceConnectorForm";
import { useUserProfileContext } from "../../lib/UserContext";
import QueryBuilder from "./QueryBuilder";
import {parseSQLSchema} from "../../lib/ParsedSchema";

const InteractiveSearchPage: React.FC = () => {
    const [sources, setSources] = useState<any[]>([]);
    const [selectedSourceUUID, setSelectedSourceUUID] = useState<string | null>(null);
    const [selectedSource, setSelectedSource] = useState<any>(null);
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [query, setQuery] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // Added errorMessage state
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalItems, setTotalItems] = useState<number>(0);
    const { queryUserProfile, getUserProfile, hasQueriedUserProfile } = useUserProfileContext();

    const user = getUserProfile();

    // Fetch available data sources
    useEffect(() => {
        const fetchDataSources = async () => {
            try {
                const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/datasource`, {
                    method: "GET",
                    credentials: "include",
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: any = await response.json();
                setSources(data.results);
                setSelectedSourceUUID(data.results[0]?.uuid || null);
            } catch (err) {
                console.error(err);
            }
        };

        fetchDataSources();
    }, []);

    // Fetch data whenever page index, size, or query changes
    useEffect(() => {
        if (selectedSourceUUID) {
            fetchData();
            const s = sources.find((s) => s.uuid === selectedSourceUUID);
            if (s) {
                setSelectedSource(s);
            }
        }
    }, [selectedSourceUUID]);

    useEffect(() => {
        if (selectedSourceUUID) {
            fetchData();
        }
    }, [pageIndex, pageSize]);

    const fetchData = async () => {
        try {
            setLoading(true);
            setErrorMessage(null); // Clear previous error messages
            const response = await fetch(
                `https://${process.env.REACT_APP_DOMAIN_API}/actions/datasource/query/${selectedSourceUUID}`,
                {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${process.env.REACT_APP_API_KEY}`,
                    },
                    body: JSON.stringify({ query, page: { index: pageIndex, size: pageSize } }),
                }
            );

            const responseData = await response.json();
            if ((!responseData.results || responseData.results.length === 0) && responseData.message) {
                setErrorMessage(responseData.message); // Set error message if provided
                setData([]);
            } else {
                setData(responseData.results || []);
                setTotalItems(responseData.query.total_items);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setErrorMessage("An error occurred while fetching the data.");
        } finally {
            setLoading(false);
        }
    };

    const renderTable = () => {
        if (data.length === 0) {
            return <Paragraph>No data available.</Paragraph>;
        }

        const headers = Object.keys(data[0]);

        return (
            <table className="data-table">
                <thead>
                <tr>
                    {headers.map((header) => (
                        <th key={header}>{header}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        {headers.map((header) => (
                            <td className={"overflow-ellipsis"} key={header}>
                                {item[header]?.toString() || "N/A"}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        );
    };

    const parsedSchema = parseSQLSchema(selectedSource?.metadata?.schema||[]);

    const tables = Object.keys(parsedSchema.tables);
    const columns = Object.fromEntries(
        Object.entries(parsedSchema.tables).map(([tableName, tableDef]) => [
            tableName,
            tableDef.columns.map((col) => col.name),
        ])
    );

    return (
        <Content className="limits wide pad-content full gap">
            <Space direction="vertical" GapSm Wide>
                <Space direction="vertical" GapSm>
                    <Space Wide GapSm justify="space-between" align="center">
                        <Select
                            key={`${user?.uuid}-${sources.length}`}
                            defaultValue={sources[0]?.uuid || ""}
                            options={
                                !sources || sources.length === 0
                                    ? [
                                        {
                                            value: undefined,
                                            element: (
                                                <Space className={"disabled"} align="center" GapSm NoWrap>
                                                    <FontAwesomeIcon
                                                        className={"warning"}
                                                        icon={faTriangleExclamation}
                                                        fixedWidth={true}
                                                    />
                                                    <span>No Sources Available</span>
                                                </Space>
                                            ),
                                        },
                                    ]
                                    : sources.map((source) => ({
                                        value: source.uuid,
                                        element: (
                                            <Space align="center" GapSm NoWrap>
                                                <FontAwesomeIcon icon={faTable} fixedWidth={true} className={"muted-heavy"} />
                                                {(!user ||
                                                    (user && user.uuid !== source.owner?.uuid && source.options.public)) && (
                                                    <Tooltip message={"Public / Community Data Source"}>
                                                        <FontAwesomeIcon
                                                            icon={faUsers}
                                                            fixedWidth={true}
                                                            className={"muted-heavy"}
                                                        />
                                                    </Tooltip>
                                                )}
                                                <span>{source.nickname || getNickname(source) || "Unknown Source"}</span>
                                            </Space>
                                        ),
                                    }))
                            }
                            onSelected={(value: string) => setSelectedSourceUUID(value)}
                        />
                        {query && (
                            <Input
                                grow
                                type="text"
                                value={query}
                                placeholder="Enter search query"
                            />
                        )}
                        <Button type="primary" onClick={fetchData} disabled={!selectedSourceUUID || loading}>
                            Fetch Data
                        </Button>
                    </Space>


                    <QueryBuilder
                        tables={tables}
                        columns={columns}
                        onUpdate={(e: string) => {
                            setQuery(e);
                        }}
                    />

                </Space>
            </Space>
            <Space
                style={{ border: "1px solid #ccc", overflowY: "auto", height: "100%", maxHeight: "100%" }}
                Full
                Grow
                Wide
            >
                {loading ? (
                    <Space Wide Full align={"center"} justify={"center"}>
                        <Loading />
                    </Space>
                ) : errorMessage ? (
                    <Space Wide Full align={"center"} justify={"center"}>
                        <Paragraph>{errorMessage}</Paragraph>
                    </Space>
                ) : data.length === 0 ? (
                    <Space Wide Full align={"center"} justify={"center"}>
                        <Empty description={"No Results"} />
                    </Space>
                ) : (
                    renderTable()
                )}
            </Space>

            {!loading && (
               <Space justify={"space-between"} Gap Wide>

                   <Pagination
                    current={pageIndex}
                    total={Math.ceil(totalItems / pageSize)}
                    onChange={(p: number) => {
                        setPageIndex(p);
                    }}
                    label={
                        <span>
                            Page {pageIndex + 1} of {Math.ceil(totalItems / pageSize)} ({totalItems} items)
                        </span>
                    }
                />

                   <Space direction="horizontal" Gap align="center">
                       <span>Page Size:</span>
                       <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize.toString()}>
                           <option value="5">5</option>
                           <option value="10">10</option>
                           <option value="20">20</option>
                       </select>
                   </Space>

               </Space>
            )}
        </Content>
    );
};

export default InteractiveSearchPage;
