import React, {useEffect, useState} from "react";
import DatasourceConnectorForm, {getNickname} from "./DatasourceConnectorForm";
import {Button, Card, Col, Divider, Paragraph, Row, Space, Title, Content} from "../layout/Content";
import {useNavigate, useParams} from "react-router-dom";
import {faCircleExclamation, faPlus, faSpinner, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Badge from "../part/Badge";
import {Collapsible, Empty, Loading, Result} from "../layout/Defaults";
import {faCircleCheck, faCircleQuestion} from "@fortawesome/free-regular-svg-icons";
import {xcode} from "react-syntax-highlighter/dist/esm/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";
import {useUserProfileContext} from "../../lib/UserContext";

const Datasource: React.FC = () => {

    const navigate = useNavigate();

    const {page} = useParams<{ page: string }>();
    const {controller} = useParams<{ controller: string }>();
    const {uuid} = useParams<{ uuid: string }>();
    const { queryUserProfile, getUserProfile, setUserProfile, hasQueriedUserProfile } = useUserProfileContext();
    const user = getUserProfile();

    const [sources, setSources] = useState<any[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        setError(null);
        setLoading(true);
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/datasource`,{
                    method: 'GET',
               //     mode: 'cors',
                    credentials: 'include',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data: any = await response.json();
                console.log("SOURCES DATA", data);
                setSources(data.results);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch user profile.');
                setLoading(false);
                console.error(err);
            }
        };
        fetchUserProfile();
    }, [page, controller]);


    const handleAnalyze = async (uuid:string)=>{

        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/actions/datasource/analysis/${uuid}`,{
                method: 'GET',
            //    mode: 'cors',
                credentials: 'include'
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data: any = await response.json();
            console.log("ANALYSIS DATA", data);
            //setSources(data.results);
            //setLoading(false);
        } catch (err) {
           // setError('Failed to fetch user profile.');
           // setLoading(false);
            console.error(err);
        }

    }

    const btn = ()=>{
        return <Button type={"primary"} onClick={()=>{
            navigate("/databox/sources/add");
        }}><FontAwesomeIcon icon={faPlus} fixedWidth />Add New Source</Button>;
    }


    const getIntegration = (source:any)=>{
        if (!source || source.integrationType === undefined){
            return <span><FontAwesomeIcon spinPulse fixedWidth icon={faSpinner} /> Unknown</span>;
        }

        if (source.integrationType === 0){
            return <span className={"warning"}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} /> Limited Schema Only</span>;
        }

        if (source.integrationType === 1){
            return <span className={"success"}><FontAwesomeIcon fixedWidth icon={faCircleCheck} /> Full Read Access</span>;
        }

        return <></>
    }

    const getPrivacy = (source:any)=>{
        if (!source || source.dataPrivacyType === undefined){
            return <span><FontAwesomeIcon spinPulse fixedWidth icon={faSpinner} /> Unknown</span>;
        }

        if (source.dataPrivacyType === 0){
            return <span className={"success"}><FontAwesomeIcon fixedWidth icon={faCircleCheck} /> None, Full Access</span>;
        }

        if (source.dataPrivacyType === 1){
            return <span className={"muted"}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} /> Limited, No External Data</span>;
        }

        if (source.dataPrivacyType === 2){
            return <span className={"muted"}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} /> Lockdown, Low Explorer Access</span>;
        }

        if (source.dataPrivacyType === 3){
            return <span className={"danger"}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} /> Anonymous, No Data Source Visibility</span>;
        }

        return <></>
    }

    const getStatus = (source:any)=>{
        if (!source || !source.metadata){
            return <span><FontAwesomeIcon spinPulse fixedWidth icon={faSpinner} /> Awaiting Analysis</span>;
        }

        if (source.metadata && source.metadata.errorMessages && source.metadata.errorMessages.length){
            return <span className={"danger"}><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} /> Error</span>;
        }

        if (source.metadata && source.metadata.schema && source.metadata.schema.length){
            return <span className={"success"}><FontAwesomeIcon fixedWidth icon={faCircleCheck} /> Processed</span>;
        }

        if (source.metadata && source.metadata.semantics){
            return <span className={"default"}><FontAwesomeIcon fixedWidth icon={faCircleCheck} /> Processed</span>;
        }

        return <span className={"muted-heavy default"}><FontAwesomeIcon fixedWidth icon={faCircleQuestion} /> Unknown</span>;
    }

    const getMessages = (source:any)=>{


        if (!user || (source.options.public && source.owner.uuid !== user.uuid) ){
          //  return <>Public Data Source, no </>;
        }

        if (!source || !source.metadata){
            return <>
                <Card PadSm className={"ghost"}><Space GapSm align={"center"}><FontAwesomeIcon icon={faTriangleExclamation} fixedWidth /><span>No Metadata, awaiting Analysis</span></Space></Card>
            </>;
        }

        if (source.metadata && source.metadata.erros && source.metadata.errors.length){
            return <div>
                {source.metadata.errors.map((message:string)=>{
                    return <Card PadSm className={"danger"}><Space GapSm align={"center"}><FontAwesomeIcon icon={faCircleExclamation} fixedWidth />{message}</Space></Card>
                })}
            </div>;
        }
        return <></>
    }


    if (controller==="add"){
        return         <Content className={"limits wide pad-content full"}> <DatasourceConnectorForm/></Content>
    }

    if (controller==="edit" && uuid){

        const user = getUserProfile();

        const datasource = sources?.filter((a)=>{return a.uuid === uuid})[0];


        if (user && datasource && datasource.owner.uuid === user.uuid) {
            //return <span>{JSON.stringify(datasource)}</span>;
            return <Content className={"limits wide pad-content full"}><DatasourceConnectorForm datasource={datasource}/></Content>;
        }else{
           // navigate("/databox/sources");
        }
    }

    const sourceCard=(source:any)=>{
        return <Card Pad Wide>
            <Space direction={"vertical"} GapSm Wide>
                <Space Wide justify={"space-between"} align={"center"} NoWrap>

                    <Title className={"overflow-ellipsis"}>{getNickname(source)}</Title>

                    <Space GapSm>
                        {(user && source.owner.uuid === user.uuid)&&<Button onClick={()=>{
                        handleAnalyze(source.uuid);
                    }}>Analyze</Button>}
                        <Button disabled>Data Explorer</Button>
                        {(user && source.owner.uuid === user.uuid)&&<Button onClick={()=>{
                            navigate("/databox/sources/edit/"+source.uuid);
                        }}>Edit</Button>}

                    </Space></Space>
                    <small>{source.uuid}</small>

                <Row GapSm>
                    <Col xs={24} md={12} lg={6}>
                        <Paragraph>
                            <span className={"muted"}><strong>Status</strong></span>
                        <Paragraph>{getStatus(source)}</Paragraph>
                        </Paragraph>
                    </Col>

                    <Col xs={24} md={12} lg={6}>
                        <Paragraph>
                            <span className={"muted"}><strong>Integration</strong></span>
                            <Paragraph>{getIntegration(source)}</Paragraph></Paragraph>
                    </Col>

                    <Col xs={24} md={12} lg={6}>
                        <Paragraph>
                            <span className={"muted"}><strong>Data Privacy</strong></span>
                            <Paragraph>{getPrivacy(source)}</Paragraph></Paragraph>
                    </Col>

                </Row>

                {(source.metadata?.schema&&source.metadata?.schema.length!==0)&&<Collapsible title={"Data Source Schema"}>
                    <SyntaxHighlighter
                        language="sql"
                        style={xcode}
                        wrapLongLines={true}
                        customStyle={{
                            fontSize:"9pt",
                            borderRadius: "12px",
                            padding: "16px",
                            background: "#fcfcfc"
                        }}
                    >{source.metadata.schema.join("\n")}</SyntaxHighlighter>
                </Collapsible>}

                {getMessages(source)}
            </Space>
        </Card>;
    }

    const isReadyToDisplay=()=>{
       // return hasQueriedUserProfile() && (sources);

        if (!hasQueriedUserProfile() || (!hasQueriedUserProfile() && loading && !sources)){
           return <Loading description={"Loading Data Sources"} className={"wide"} />;
        }

        if (sources){

            if (sources && sources?.length){

                const publicSources = (sources||[]).filter((source)=>{return source.options?.public});
                const privateSources = (sources||[]).filter((source)=>{return !source.options?.public});


                return <>

                    {(hasQueriedUserProfile() && !loading && sources && privateSources.length > 0) && <>
                        {privateSources && privateSources?.map((source) => {
                            return sourceCard(source);
                        })}
                    </>}

                    {(hasQueriedUserProfile() && !loading && sources && publicSources.length > 0) && <>
                        <Space GapSm Grow><h3>Public Data Sources</h3><Divider/></Space>
                        {publicSources && publicSources?.map((source) => {
                            return sourceCard(source);
                        })}
                    </>}
                </>
            }else{
                return <Result status="warning" title="No Connected Data Sources"
                               subTitle="Order number: 123456789"
                               extra={btn()}/>
            }
        }

        if (hasQueriedUserProfile()){
            if (loading && !sources) {
                return <Loading description={"Loading Data Sources"} className={"wide"}/>;
            }else{
                return <Result status={"error"} title={"Failed to fetch Data Sources"} className={"wide"} subTitle={""}/>;

            }
        }

        return <>unknown</>
    }

    return (<Content className={"limits wide pad-content full"}>
        <Space Gap Wide>

        <Space Wide Gap justify={"space-between"} align={"center"}><Title>Data Sources</Title>{btn()}</Space>

        {isReadyToDisplay()}

    </Space></Content>);
};

export default Datasource;